import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "./CardPrimary.scss"

const CardPrimary = ({ curLanguage, children, className }) => {
    return (

        <Box className="recentSec" sx={{ backgroundColor: "darkblue.color" }}>
            <Typography
                className="recentItemHeader"
                variant="h3"
                sx={{ color: "recent.titleColor", backgroundColor: "secondaryColor.main" }}
            >
                {curLanguage}
            </Typography>
            <Grid container spacing={1} className={`${className} course`}>
                {children}
            </Grid>
        </Box>
    )
}
export default CardPrimary