import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import MainCard from "../../_Components/Common/Cards/MainCard";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { useEffect, useState } from "react";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";
import axios from "axios";
import DevSettingsPage from "../../_Components/Routing/DevSettingsPage";

export default function UserManagement() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [schoolInfo, setSchoolInfo] = useState([]);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getAllSchools`
        );
        setSchoolInfo(response.data.info);
      } catch (error) {
        console.error("Error fetching school details:", error);
      }
    };

    fetchSchoolDetails();
  }, []);

  const handleSchoolChange = (event) => {
    const { value } = event.target;
    const selectedSchoolInfo = schoolInfo.find((data) => data.id === value);
    setSchoolName(selectedSchoolInfo);
    setSelectedSchool(value);
    setIsDialogOpen(false);
  };

  return (
    <>
      <MainCard cardTitle={"User Management"}>
        <Grid container spacing={2}>
          {userInfo[0]?.department.toLowerCase() === "developer" ? (
            <>
              <Grid item>
                <PrimaryBgBtn onClick={handleOpen}>
                  Set Development Permission
                </PrimaryBgBtn>
              </Grid>
              {/* <Grid item>
                <Link
                  to={{ pathname: "/assignrole" }}
                  className="btn linkBtn primaryBtn"
                  variant="contained"
                  style={{ textDecoration: "none" }}
                >
                  Assign Role
                </Link>
              </Grid> */}
            </>
          ) : (
            <Grid item>
              <Link
                rel="preload"
                to={{ pathname: "/settings" }}
                className="btn linkBtn primaryBtn"
                variant="contained"
                style={{ textDecoration: "none" }}
              >
                Permission For School
              </Link>
            </Grid>
          )}
        </Grid>

        <CustomDialog
          isOpen={isDialogOpen}
          width={"sm"}
          onClose={handleClose}
          closeIcon
          dialogTitle={"Select School"}
        >
          <FormControl fullWidth>
            <InputLabel id="school-select-school">School</InputLabel>
            <Select
              labelId="school-select-school"
              id="school-select"
              name="selectedSchool"
              value={selectedSchool}
              input={<OutlinedInput label="school" />}
              onChange={handleSchoolChange}
            >
              {schoolInfo.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Typography>School Code: {data.id}</Typography>
                    <Typography>{data.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CustomDialog>
        {selectedSchool && (
          <DevSettingsPage
            selectedSchool={selectedSchool}
            schoolName={schoolName}
          />
        )}
      </MainCard>
    </>
  );
}
