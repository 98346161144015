import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ErrorBoundryUI from '../../_pages/page404/ErrorBoundryUI';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleOnError = (error, errorInfo) => {
      console.error('Error caught by ErrorBoundary:', error, errorInfo);
      setHasError(true);
    };
    window.addEventListener('error', handleOnError);
    return () => {
      window.removeEventListener('error', handleOnError);
    };
  }, []);

  if (hasError) {
    return <ErrorBoundryUI />
  }

  return children;
}

export default ErrorBoundary;
