import { Box, Typography } from "@mui/material";
import CustomMenu from "../../_Components/Common/CustomMenu";
import { FaRegImages } from "react-icons/fa";
import { Scrollbar } from "react-scrollbars-custom";
import "./PreviousCertificates.scss";

const PreviousCertificates = ({ groupedCertificates }) => {
  return (
    <CustomMenu
      iconwdBtn
      imgName={<FaRegImages size={22} stroke="#fff" />}
      altTxt={"Previous certificates"}
    >
      <Box className="certificateList">
        <Typography
          variant="h6"
          className="heading"
          textAlign={"center"}
          py={1}
          style={{
            backgroundColor: "#233cb3",
            color: "white",
          }}
        >
          Recent Certificates
        </Typography>
        <Scrollbar style={{ width: "100%", height: "400px" }}>
          {Object.keys(groupedCertificates).length ? (
            Object.keys(groupedCertificates).map((classsec, index) => (
              <Box key={index} className="certificateClass">
                <Typography variant="h6" className="title">Class: {classsec}</Typography>
                {groupedCertificates[classsec].map((val, index) => (
                  <Box key={index} id={val.id}>
                    <Typography variant="body1" className="stdValue">
                     <b>{val.firstname} {val.lastname}</b>: Earned {val.subject}{" "}
                      {val.title} Certificate.
                    </Typography>
                  </Box>
                ))}
              </Box>
            ))
          ) : (
            <Box>
              <Typography textAlign={"center"}>
                No certificates created.
              </Typography>
            </Box>
          )}
        </Scrollbar>
      </Box>
    </CustomMenu>
  );
};

export default PreviousCertificates;
