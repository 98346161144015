import React, { useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  List,
  ListItem,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import CustomDialog from "../../../_Components/Common/CustomDialog/CustomDialog";
import { PrimaryBgBtn } from "../../../_Components/Common/Buttons/CustomBtns";

const EventList = ({
  eventArr,
  eventIntialDateCheck,
  seteventid,
  setpopupVisible,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogEvents, setDialogEvents] = useState([]);

  const handleEventClick = (e) => {
    e.stopPropagation();
    seteventid(e.target.getAttribute("data-id"));
    setpopupVisible(true);
  };
  const handleDialogClose = (e) => {
    e.stopPropagation();
    setOpenDialog(false);
  };
  const handleMoreClick = (e) => {
    e.stopPropagation();
    setDialogEvents(eList);
    setOpenDialog(true);
  };

  const handleDialogEventClick = (e, event) => {
    e.stopPropagation();
    seteventid(event.id);
    setpopupVisible(true);
    setOpenDialog(false);
  };

  let count = 0;
  let eList = [];
  let oList = [];
  let storedEvents = [];
  eventArr?.map((val) => {
    if (val.component || val.eventtype === "course" || val.canedit) {
      count++;
      eList.push({ ...val });
      storedEvents.push({ ...val });

      if (val.eventtype === "open") {
        oList.push(val.instance);
      } else if (val.eventtype === "close") {
        let iid = oList.indexOf(val.instance);
        if (iid > -1) {
          storedEvents[iid].eventtype = "open-close";
          eList[iid].eventtype = "open-close";
          eList.pop();
        }
      }
    }
  });

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      // color: theme.palette.tooltip.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.tooltip.backgroundColor,
      color: theme.palette.tooltip.main,
      border: "1px solid white",
    },
  }));

  let eventsli = eList.map((val) => {
    return (
      <li key={val.id}>
        <BootstrapTooltip title={val.name}>
          <Button
            color={
              val.eventtype === "course"
                ? "teacherEventBtn"
                : val.canedit
                ? "userEventBtn"
                : "categoryEventBtn"
            }
            variant="contained"
            className="eventsButton"
            data-id={val.id}
            onClick={handleEventClick}
          >
            {val.name}
          </Button>
        </BootstrapTooltip>
      </li>
    );
  });

  if (eventsli.length <= 2) {
    return eventsli;
  } else {
    let eventLeft = eventsli.length - 2;
    let esli1 = eventsli.slice(0, 2);
    esli1.push(
      <li key="moreButton">
        <Button
          sx={{ color: "#000000" }}
          variant="text"
          className="eventsButton"
          onClick={handleMoreClick}
        >{`${eventLeft} more`}</Button>
      </li>
    );
    return (
      <>
        {esli1}
        <CustomDialog
          isOpen={openDialog}
          onClose={handleDialogClose}
          dialogTitle={`Events scheduled for the ${eventIntialDateCheck}th`}
          actionBtn={
            <PrimaryBgBtn onClick={handleDialogClose}>Close</PrimaryBgBtn>
          }
        >
          <Box px={2} className="eventListData">
            <List>
              {dialogEvents.map((event) => (
                <ListItem
                  className="eventLists"
                  key={event.id}
                  onClick={(e) => handleDialogEventClick(e, event)}
                >
                  {event.name}
                </ListItem>
              ))}
            </List>
          </Box>
        </CustomDialog>
      </>
    );
  }
};

export default EventList;
