import React from 'react';
import { Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';

const CoScholasticProfile = () => {
  return (
    <Box sx={{ padding: 2 }}>
      {/* Co-Scholastic Profile */}
      <Box sx={{ marginBottom: 5 }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography variant="h3" component="div" sx={{ border: 1, paddingX: 4, paddingY: 2 }}>
            CO-SCHOLASTIC PROFILE
          </Typography>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="co-scholastic profile table" sx={{ border: 1 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" rowSpan={2} sx={{ fontWeight: 'bold', borderRight: 1 }}>
                  Cleanliness and Discipline
                </TableCell>
                <TableCell align="center" sx={{ borderRight: 1 }}>
                  TERM I
                </TableCell>
                <TableCell align="center">TERM II</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={{ borderRight: 1 }}>
                  April-September
                </TableCell>
                <TableCell align="center">October-March</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['Art', 'Music', 'Sports', 'Dance'].map((activity, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ padding: 0, fontWeight: 'bold', borderRight: 1 }}>
                    {activity}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: 0, borderRight: 1 }}>
                    <TextField
                      value="A"
                      InputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      sx={{ textAlign: 'center', border: 'none' }}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ padding: 0 }}>
                    <TextField
                      value="A"
                      InputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      sx={{ textAlign: 'center', border: 'none' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

    
      <Box>
        <Grid container justifyContent="center" alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography variant="h3" component="div" sx={{ border: 1, paddingX: 4, paddingY: 2 }}>
            SCHOLASTIC PROFILE
          </Typography>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="scholastic profile table" sx={{ border: 1 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" rowSpan={3} sx={{ fontWeight: 'bold', borderRight: 1 }}>
                  <Box sx={{ border: 1, paddingX: 4 }}>ENGLISH</Box>
                </TableCell>
                <TableCell align="center" sx={{ borderRight: 1 }}>
                  TERM I
                </TableCell>
                <TableCell align="center">TERM II</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={{ borderRight: 1 }}>
                  April-September
                </TableCell>
                <TableCell align="center">October-March</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={{ borderRight: 1 }}>
                  GRADE
                </TableCell>
                <TableCell align="center">GRADE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['Listening Skills', 'Speaking Skills', 'Reading Skills', 'Writing Skills'].map((skill, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ padding: 0, paddingX: 2, borderRight: 1 }}>{skill}</TableCell>
                  <TableCell align="center" sx={{ padding: 0, borderRight: 1 }}>
                    <TextField
                      value={index === 0 ? "B" : "O"} // Different grades for the example
                      InputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      sx={{ textAlign: 'center', border: 'none' }}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ padding: 0 }}>
                    <TextField
                      value={index === 0 ? "B" : "O"} 
                      InputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      sx={{ textAlign: 'center', border: 'none' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell align="center" rowSpan={3} sx={{ fontWeight: 'bold', borderRight: 1 }}>
                  <Box sx={{ border: 1, paddingX: 4 }}>HINDI</Box>
                </TableCell>
                <TableCell colSpan={2} sx={{ padding: 0 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['Listening Skills', 'Speaking Skills', 'Reading Skills', 'Writing Skills'].map((skill, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ padding: 0, paddingX: 2, borderRight: 1 }}>{skill}</TableCell>
                  <TableCell align="center" sx={{ padding: 0, borderRight: 1 }}>
                    <TextField
                      value={index === 2 ? "O" : "A"} 
                      InputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      sx={{ textAlign: 'center', border: 'none' }}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ padding: 0 }}>
                    <TextField
                      value={index === 2 ? "O" : "A"} 
                      InputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      sx={{ textAlign: 'center', border: 'none' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold', borderRight: 1 }}>
                  <Box sx={{ border: 1, paddingX: 4 }}>MATHEMATICS</Box>
                </TableCell>
                <TableCell align="center" sx={{ padding: 0, borderRight: 1 }}>
                  <TextField
                    value="A"
                    InputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    sx={{ textAlign: 'center', border: 'none' }}
                  />
                </TableCell>
                <TableCell align="center" sx={{ padding: 0 }}>
                  <TextField
                    value="A"
                    InputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    sx={{ textAlign: 'center', border: 'none' }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold', borderRight: 1 }}>
                  <Box sx={{ border: 1, paddingX: 4 }}>EVS</Box>
                </TableCell>
                <TableCell align="center" sx={{ padding: 0, borderRight: 1 }}>
                  <TextField
                    value="A"
                    InputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    sx={{ textAlign: 'center', border: 'none' }}
                  />
                </TableCell>
                <TableCell align="center" sx={{ padding: 0 }}>
                  <TextField
                    value="A"
                    InputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    sx={{ textAlign: 'center', border: 'none' }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CoScholasticProfile;
