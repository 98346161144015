import axios from "axios";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import PrincipalFilter from "../../../_Components/Common/Filter/PrincipalFilter";
import { useState } from "react";
import { Card, Container } from "@mui/material";
import StudentRecord from "../../Attendance/StudentRecord";

export default function StaffAttendance() {
  const nToken = localStorage.getItem("nToken");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYr = localStorage.getItem("financialYear");
  const [attendanceData, setAttendanceData] = useState(null);
  const [studentInfo, setStudentInfo] = useState([]);
  const [filterDepartmentData, setFilterDepartmentData] = useState("");

  // Get student info for filtering purposes
  const handleFilter = (stdclass, stdsection, stdsubject, department) => {
    fetchData(department, stdsubject, stdsection, stdclass);
    setFilterDepartmentData(department);
  };

  const fetchData = async (
    filterDepartment,
    filterSubject,
    filterSection,
    filterClass
  ) => {
    try {
      const enroledURL = `${process.env.REACT_APP_nodewsPrefix}/FetchUsers`;
      const response = await axios.post(
        enroledURL,
        {
          department: filterDepartment,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );

      if (filterDepartment === "student") {
        const studentData = response.data.records.filter((val) => {
          // Check if the department is student and institution matches
          const isStudent =
            val.department?.toLowerCase() === "student" &&
            val.institution === userinfo[0].institution;
          // If no class or section is selected, show all students
          if (!filterClass && !filterSection) {
            return isStudent;
          }
          // If only class is selected, filter students of that class
          if (filterClass && !filterSection) {
            return isStudent && val.class === filterClass;
          }
          // If both class and section are selected, filter students of that class & section
          if (filterClass && filterSection) {
            return (
              isStudent &&
              val.class === filterClass &&
              val.section === filterSection
            );
          }
          // If section is selected without class, filter students of that section
          if (!filterClass && filterSection) {
            return isStudent && val.section === filterSection;
          }
          return false;
        });
        setStudentInfo(studentData);
        console.log(studentData, "userRecords1");
      } else if (filterDepartment === "Pedagogy") {
        const studentData = response.data.records.filter(
          (val) =>
            val.department?.toLowerCase() === "pedagogy" &&
            val.institution === userinfo[0].institution
        );
        setStudentInfo(studentData);
        console.log(studentData, "userRecords2");
      }

      console.log(studentInfo, "userRecords3");

      const studentIds = studentInfo?.map((data) => data.id);

      console.log(studentIds, "classTeacherData");
      const attendanceUrl = `${process.env.REACT_APP_nodewsPrefix}/viewAttendance`;
      const attendResponse = await axios.post(
        attendanceUrl,
        {
          student_id: studentIds,
          class_id: "",
          section_id: "",
          financial_year: financialYr,
          day: "",
          month: "",
          schoolcode: userinfo[0].institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      console.log(attendResponse.data, "responsesss");

      const updatedAttendanceData = studentIds.reduce((content, studentId) => {
        content[studentId] = attendResponse.data.attendance_data[studentId];
        return content;
      }, {});
      setAttendanceData(updatedAttendanceData);
      console.log(updatedAttendanceData, "updatedAttendanceData");
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  return (
    <>
      <MainCard cardTitle="User Attendance">
        <PrincipalFilter
          forDepartment
          forClass
          forSection
          onselectionchangeData={handleFilter}
        />
      </MainCard>
      <Container maxWidth="xl">
        {filterDepartmentData && (
          <Card
            sx={{
              backgroundColor: "cardColor.main",
              color: "cardColor.contrast",
              position: "relative",
              marginTop: "16px",
              padding: "10px 0 ",
            }}
            elevation={5}
            className="customCard"
          >
            {studentInfo && (
              <StudentRecord
                studentInfo={studentInfo}
                attendanceData={attendanceData}
              />
            )}
          </Card>
        )}
      </Container>
    </>
  );
}
