import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TextIcon = ({ text, icon }) => {
  return (
    <Box display="flex" alignItems="center" mb={0}>
      {icon}
      <Typography variant="h6" marginLeft={1}>
        {text}
      </Typography>
    </Box>
  );
};

export default TextIcon;
