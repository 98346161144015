import React from "react";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";

const ChapterProgress = () => {
  const data1 = useSelector((state) => state.items);

  const uniqueData = (data) => {
    const uniqueNames = new Set();
    const result = [];
  
    data.forEach(item => {
      if (!uniqueNames.has(item.classsection)) {
        uniqueNames.add(item.classsection);
        result.push({ name: item.classsection, value: item.class_section_count });
      }
    });
  
    return result;
  };
  
  const data = uniqueData(data1.classSecData);


  const option = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c}",
    },

    legend: {
      formatter: function (name) {
        return name.toUpperCase();
      },
      orient: "horizontal",
      left: 10,
      top: 0,
    },
    toolbox: {
      show: true,
      orient: "horizontal",
      right: 10,
      top: 0,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        magicType: { show: false, type: ["bar", "stack"] },
        restore: { show: false },
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: "Student Count",
        type: "pie",
        radius: "70%",
        center: ["50%", "50%"],
        selectedMode: "single",
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          position: "inside",
          alignTo: "labelLine",
          bleedMargin: 5,
          margin: 0,
          fontSize: 16,
          fontWeight: "bold",
          show: true,
          formatter: " {c} ",
        },
        data: data,
      },
    ],
  };

  return <ReactEcharts option={option} />;
};

export default ChapterProgress;
