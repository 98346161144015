import React from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  TextareaAutosize,
} from '@mui/material';

const grades = ['A1', 'A1', 'A1', 'A1', 'A1', 'A1', 'A1'];
const subjects = [
  'English',
  'Hindi',
  'Mathematics',
  'EVS',
  'Computer Science',
  'General Awareness',
  'Third Language',
];

const ScholasticAssessmentTerm1 = () => {
  return (
    <Box  sx={{ padding: '16px', border: '1px solid black' }}>
      <Box >
        <Grid container spacing={3} sx={{ marginBottom: '24px' }}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5" component="h3" sx={{ border: '1px solid black', padding: '8px' }}>
                SCHOLASTIC ASSESSMENT TERM I
              </Typography>
              <Typography variant="h6">(April-September)</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="scholastic assessment table" border={1}>
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan={3} align="center">SUBJECT</TableCell>
                    <TableCell colSpan={4} align="center">Internal Assessment</TableCell>
                    <TableCell rowSpan={2} align="center">Mid Term<br />Examination/<br />Assessment</TableCell>
                    <TableCell rowSpan={3} align="center">Term I<br />Subject<br />Grade</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="center">GRADE</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Periodic<br />Test</TableCell>
                    <TableCell align="center">Multiple<br />Assessment</TableCell>
                    <TableCell align="center">Portfolio</TableCell>
                    <TableCell align="center">Subject<br />Enrichment</TableCell>
                    <TableCell align="center">GRADE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subjects.slice(0, 4).map((subject, index) => (
                    <TableRow key={subject}>
                      <TableCell>{subject}</TableCell>
                      {grades.map((grade, idx) => (
                        <TableCell key={idx} align="center">
                          <TextField value={grade} variant="standard" inputProps={{ readOnly: true }} fullWidth />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {subjects.slice(4).map((subject, index) => (
                    <TableRow key={subject}>
                      <TableCell>{subject}</TableCell>
                      <TableCell colSpan={4}></TableCell>
                      <TableCell align="center">
                        <TextField value={grades[0]} variant="standard" inputProps={{ readOnly: true }} fullWidth />
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '16px' }}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography variant="h6">Attendance</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField value="92/98" variant="outlined" fullWidth inputProps={{ readOnly: true }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '32px' }}>
            <Box sx={{ border: '1px solid black' }}>
              <Typography variant="h6" sx={{ padding: '16px' }}>Remarks</Typography>
              <Box sx={{ padding: '16px' }}>
                <TextareaAutosize
                  value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                  readOnly
                  minRows={8}
                  style={{ width: '100%', border: '1px solid black', padding: '8px' }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: '16px',marginLeft:'37px' }}>
            {['Class Teacher', 'Headmistress', 'Principal', 'Parent'].map((role, idx) => (
              <Grid item xs={3} key={role}>
                <Card sx={{ border: '1px solid black' }}>
                  <CardContent sx={{ padding: '0', textAlign: 'center' }}>
                    <Box sx={{ height: '80px', cursor: 'pointer', position: 'relative' }}>
                      <img src="images/signature.png" alt="signature" style={{ width: '100%', height: '100%' }} />
                    </Box>
                    <Typography variant="h6">{role}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ScholasticAssessmentTerm1;
