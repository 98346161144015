import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { usePermissions } from "../Permission/PermissionsContext";

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

const ProtectedRoute = ({ element, requiredPermissions }) => {
  const navigate = useNavigate();
  const nToken = localStorage.getItem("nToken");
  const { permissions } = usePermissions();
  try {
    var user = JSON.parse(localStorage.getItem("userInfo"));
    var department = user?.[0].department.toLowerCase();
  } catch (error) {
    localStorage.clear();
    window.location.reload();
    navigate("/");
  }

  /* axios.interceptors.request.use((config) => {
    if (!config.url.includes(".php")) {
      config.headers.Authorization = `Bearer ${nToken}`;
    }
    return config;
  }); */

  useEffect(() => {
    try {
      if (isAuthenticated()) {
        axios.interceptors.response.use(
          (response) => {
            if (!response.config.url.includes(".php")) {
              if (response.data.success != undefined && !response.data.success) {
                localStorage.clear();
                navigate("/");
                window.location.reload();
              }
            }
            return response;
          },
          (error) => {
            if (
              error.response &&
              (error.response.status === 401 || error.response.status === 403)
            ) 
            return Promise.reject(error);
          }
        );
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log(error)
    }
  }, [nToken, navigate, permissions, requiredPermissions, department]);
  if (
    isAuthenticated() &&
    (department === "developer" || permissions[requiredPermissions]?.read)
  ) {
    return element;
  } else if (department) {
    return <Navigate to="/Unauthorized" />;
  } else {
    return <Navigate to="/Unauthorized" />;
  }
};

export default ProtectedRoute;
