
const initialState = {
    userInfo: [],
    myCourseData: [],
    classSecData: []
}

export default function items(state = initialState, action) {
    //console.log("reducer: ", action);
    switch (action.type) {
        case "ADD_DATA":
            return {
                ...state,
                userInfo: action.data
            }
            break
        case "CLASSSEC_DATA":
            return {
                ...state,
                classSecData: action.data
            }
            break
        case "MYCOURSE_DATA":
        return {
            ...state,
            myCourseData: action.data
        }
        break
        default:
            return state

    }
}