export const timetable = [
    {
      day: "Monday",
      period: [
        { teacher: "Class 5 A", subject: "Science" },
        { teacher: "Class 5 B", subject: "Science" },
        { teacher: "Class 4 B", subject: "English" },
        { teacher: "Class 4 D", subject: "English" },
        { teacher: "INTERVAL", subject: "Interval" },
        { teacher: "Class 4 C", subject: "Science" },
        { teacher: "Free", subject: "" },
        { teacher: "Class 3 B", subject: "English" },
        { teacher: "Class 3 E", subject: "English" },
      ],
    },
    {
      day: "Tuesday",
      period: [
        { teacher: "Class 4 B", subject: "English" },
        { teacher: "Class 5 A", subject: "Science" },
        { teacher: "Class 4 B", subject: "English" },
        { teacher: "Class 4 C", subject: "Science" },
        { teacher: "INTERVAL", subject: "Interval" },
        { teacher: "Class 4 D", subject: "English" },
        { teacher: "Class 4 D", subject: "English" },
        { teacher: "Class 3 E", subject: "English" },
        { teacher: "Class 3 B", subject: "English" },
      ],
    },
    {
      day: "Wednesday",
      period: [
        { teacher: "Class 4 D", subject: "English" },
        { teacher: "Class 4 B", subject: "English" },
        { teacher: "Class 4 B", subject: "English" },
        { teacher: "Class 4 C", subject: "Science" },
        { teacher: "INTERVAL", subject: "Interval" },
        { teacher: "Class 3 E", subject: "English" },
        { teacher: "Class 5 A", subject: "Science" },
        { teacher: "Class 3 B", subject: "English" },
        { teacher: "Substitute", subject: "NA" },
      ],
    },
    {
      day: "Thursday",
      period: [
        { teacher: "Class 4 D", subject: "English" },
        { teacher: "Class 3 E", subject: "English" },
        { teacher: "Class 5 A", subject: "Science" },
        { teacher: "Class 4 B", subject: "English" },
        { teacher: "INTERVAL", subject: "Interval" },
        { teacher: "Substitute", subject: "NA" },
        { teacher: "Substitute", subject: "NA" },
        { teacher: "Class 5 B", subject: "Science" },
        { teacher: "Class 4 C", subject: "Science" },
      ],
    },
    {
      day: "Friday",
      period: [
        { teacher: "Class 5 A", subject: "Science" },
        { teacher: "Class 4 D", subject: "English" },
        { teacher: "Class 4 B", subject: "English" },
        { teacher: "Class 3 E", subject: "English" },
        { teacher: "INTERVAL", subject: "Interval" },
        { teacher: "Class 4 C", subject: "Science" },
        { teacher: "Class 4 C", subject: "Science" },
        { teacher: "Class 4 B", subject: "English" },
        { teacher: "Substitute", subject: "NA" },
      ],
    },
    {
      day: "Saturday",
      period: [
        { teacher: "Class 4 B", subject: "English" },
        { teacher: "Class 4 C", subject: "Science" },
        { teacher: "Class 4 D", subject: "English" },
        { teacher: "Class 3 B", subject: "English" },
        { teacher: "INTERVAL", subject: "Interval" },
        { teacher: "Class 5 A", subject: "Science" },
        { teacher: "Class 3 E", subject: "English" },
        { teacher: "Class 3 E", subject: "English" },
        { teacher: "Substitute", subject: "NA" },
      ],
    },
  ];
  