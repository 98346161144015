import {
  Alert,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Snackbar,
  SnackbarContent,
  TextField,
} from "@mui/material";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { useState } from "react";
import axios from "axios";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentErrorMessage, setCurrentErrorMessage] = useState("");
  const [newPasswordMessage, setNewPasswordMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const userid = userinfo[0]?.id;
  const token = localStorage.getItem("token");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setCurrentErrorMessage("");
    setErrorMessage("");
    setSuccessMessage("");
    setConfirmPassword("");
    setNewPasswordMessage("");
  };
  const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,15}$/;
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "currentPassword") {
      const newValue = value.slice(0, 15);
      setCurrentPassword(newValue);
    } else if (name === "newPassword") {
      const newValue = value.slice(0, 15);
      setNewPassword(newValue);
    } else if (name === "confirmPassword") {
      const newValue = value.slice(0, 15);
      setConfirmPassword(newValue);
    }
  };

  const handleSubmit = () => {
    let hasError = false;

    if (!currentPassword) {
      setCurrentErrorMessage("Please fill this field.");
      hasError = true;
    } else {
      setCurrentErrorMessage("");
    }
    if (!newPassword) {
      setNewPasswordMessage("Please fill this field.");
      hasError = true;
    } else {
      setNewPasswordMessage("");
    }

    if (!confirmPassword) {
      setErrorMessage("Please fill this field.");
      hasError = true;
    } else {
      setErrorMessage("");
    }

    if (hasError) {
      return;
    }

    // Additional validation: Check if new password matches confirm password
    if (newPassword !== confirmPassword) {
      setErrorMessage("Confirm password does not match the new password.");
      return;
    }

    // Additional validation: Check if the new password matches the required pattern
    if (!pattern.test(newPassword)) {
      setErrorMessage(
        "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    const baseURL = `${process.env.REACT_APP_wsPrefix}/customwebservice/user/password.php?wstoken=${token}&wsfunction=password_change`;
    axios
      .put(baseURL, {
        oldpassword: currentPassword,
        newpassword: newPassword,
        confirmpassword: confirmPassword,
        userid: userid,
      })
      .then((response) => {
        console.log(response.data, "password");
        if (response.data.success === false) {
          setCurrentErrorMessage(response.data.error);
          setSuccessMessage("");
        } else {
          setSuccessMessage(response.data.success_msg);
          setCurrentErrorMessage("");
          setOpen(false);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setErrorMessage("");
          setNewPasswordMessage("");
        }
      })
      .catch((error) => {
        console.error("Error while changing password:", error);
        setCurrentErrorMessage("An error occurred. Please try again later.");
        setSuccessMessage("");
      });
  };

  return (
    <>
      <PrimaryBgBtn onClick={handleOpen}>Change Password</PrimaryBgBtn>
      <CustomDialog
        isOpen={open}
        width={"xs"}
        onClose={handleClose}
        dialogTitle={"Change Password"}
      >
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
        <form onSubmit={handleSubmit} style={{ padding: "10px 20px" }}>
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            label="Current Password"
            type="password"
            name="currentPassword"
            value={currentPassword}
            onChange={handleChange}
            error={Boolean(currentErrorMessage)}
            helperText={currentErrorMessage}
          />

          <TextField
            fullWidth
            sx={{ mb: 2, color: newPasswordMessage ? "red" : "inherit" }}
            label="New Password"
            type="text"
            name="newPassword"
            value={newPassword}
            onChange={handleChange}
            error={Boolean(newPasswordMessage)}
            helperText={newPasswordMessage}
          />

          <TextField
            fullWidth
            sx={{
              mb: 2,
              color: errorMessage ? "red" : "inherit",
              borderColor: errorMessage ? "red" : "inherit",
            }}
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleChange}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
          />

          <Box textAlign={"center"}>
            <PrimaryBgBtn type="submit">Save</PrimaryBgBtn>
          </Box>

          {/* <Snackbar
              open={!!errorMessage}
              onClose={() => setErrorMessage("")}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={60000}
            >
              <SnackbarContent
                sx={{ backgroundColor: "redColor.color" }}
                message={errorMessage}
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setErrorMessage("")}
                  >
                    OK
                  </Button>
                }
              />
            </Snackbar> */}

          <Snackbar
            open={!!successMessage}
            onClose={() => setSuccessMessage("")}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
          >
            <SnackbarContent
              sx={{ backgroundColor: "greenColor.color" }}
              message={successMessage}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setSuccessMessage("")}
                >
                  OK
                </Button>
              }
            />
          </Snackbar>
        </form>
      </CustomDialog>
    </>
  );
};

export default ChangePassword;
