import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./FeeStatus.scss";
import { useState } from "react";
// import Popupclose from "../../_Components/Utils/Popup/PopupClose";
import axios from "axios";

const FeeInfo = ({ data }) => {
  let feeData = data;
  const [open, setOpen] = useState(false);
  const [monthFee, setMonthFee] = useState([]);
  const financialYear = localStorage.getItem("financialYear");

  const handleClose = () => {
    setOpen(false);
  };
  const feeMonth = (admiNo) => {
    const allMonthFee = `${process.env.REACT_APP_nodewsPrefix}/getAllFeedata`;
    const requestData = {
      admission_no: admiNo,
      show_all: 1,
      financial_year: financialYear,
    };
    console.log(requestData, "requestData");
    axios
      .post(allMonthFee, requestData)
      .then((response) => {
        setMonthFee(response.data);
        console.log("month fees", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setOpen(true);
  };
  const getMonthName = (monthNumber) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[monthNumber - 1];
  };
  console.log(feeData, "feeDataInfo")
  // Convert month numbers to month names
  const monthNames = Object.keys(monthFee).map((monthNumber) =>
    getMonthName(monthNumber)
  );

  console.log(monthNames);
  return (
    <Box className="feeInfo" m={3}>
      <TableContainer className="customTable">
        <Table sx={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead sx={{ backgroundColor: "table.headerBg" }}>
            <TableRow>
              <TableCell sx={{ color: "table.contrast", maxWidth: "80px" }}>
                Admission no
              </TableCell>
              <TableCell sx={{ color: "table.contrast" }} align="left">
                Name
              </TableCell>
              <TableCell sx={{ color: "table.contrast" }} align="left">
                Class & Sec
              </TableCell>
              <TableCell sx={{ color: "table.contrast" }} align="left">
                Father Name
              </TableCell>
              <TableCell sx={{ color: "table.contrast" }} align="left">
                Month
              </TableCell>
              <TableCell sx={{ color: "table.contrast" }} align="left">
                Amount
              </TableCell>
              <TableCell sx={{ color: "table.contrast" }} align="left">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feeData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography sx={{color: "cardColor.contrast"}}>No such Data is available</Typography>
                </TableCell>
              </TableRow>
            ) : (
              feeData.map((feeDataInfo) => (
                <TableRow key={feeDataInfo.admission_no}>
                  <TableCell sx={{ color: "cardColor.contrast" }}>
                    <b>{feeDataInfo.admission_no}</b>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => feeMonth(feeDataInfo.admission_no)}
                    sx={{ cursor: "pointer", color: "cardColor.contrast" }}
                  >
                    {feeDataInfo.name}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "cardColor.contrast" }}>
                    {feeDataInfo.class}
                    {feeDataInfo.section}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "cardColor.contrast" }}>
                    {feeDataInfo.father_name}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "cardColor.contrast" }}>
                    {feeDataInfo.paid_for}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ color: "cardColor.contrast" }}
                    className="amountSec"
                  >
                    <Box>
                      <b>Development Fee:</b>₹{feeDataInfo.developmenet_fee}
                    </Box>

                    <Box>
                      <b>Tution Fee:</b>₹{feeDataInfo.tution_fee}
                    </Box>
                    <Box className="totalAmt">
                      <b>Total Fee:</b>₹{" "}
                      {parseInt(feeDataInfo.developmenet_fee, 10, 0) +
                        parseInt(feeDataInfo.tution_fee, 10)}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <p
                      className={`status ${
                        feeDataInfo.status === 1 ? "success" : "failed"
                      }`}
                    >
                      {feeDataInfo.status === 1 ? "Paid" : "Not Paid"}
                    </p>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* dialog for all month  */}
      <Dialog
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
        >
          {/* <Popupclose close={handleClose} /> */}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
        >
          <TableContainer className="customTable dilogTbl">
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ backgroundColor: "table.headerBg" }}>
                <TableRow>
                  <TableCell
                    sx={{ color: "table.contrast", minWidth: "180px" }}
                  >
                    Month
                  </TableCell>
                  <TableCell sx={{ color: "table.contrast" }} align="left">
                    <TableRow className="insideRow">
                      <TableCell sx={{ color: "table.contrast" }} align="left">
                        Time Period
                      </TableCell>
                      <TableCell sx={{ color: "table.contrast" }} align="left">
                        Amount
                      </TableCell>
                      <TableCell sx={{ color: "table.contrast" }} align="left">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(monthFee).map((month, i) => (
                  <TableRow key={month}>
                    <TableCell sx={{ color: "cardColor.contrast" }}>
                      <h3>{monthNames[i]}</h3>
                    </TableCell>
                    <TableCell sx={{ color: "cardColor.contrast" }}>
                      {Array.isArray(monthFee[month].amount) ? (
                        monthFee[month].amount.map((amount, i) => (
                          <TableRow key={i} className="insideRow">
                            <TableCell sx={{ color: "cardColor.contrast" }}>
                              {monthFee[month].time_period[i]}
                            </TableCell>
                            <TableCell sx={{ color: "cardColor.contrast" }}>
                              ₹{amount}
                            </TableCell>
                            <TableCell sx={{ color: "cardColor.contrast" }}>
                              {monthFee[month].paid_status[i]}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          <small> No Data found</small>
                        </p>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FeeInfo;
