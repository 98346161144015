import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import { IoAddCircleOutline } from "react-icons/io5";
import { AiOutlineMinusCircle } from "react-icons/ai";

const TimTblStructure = ({
  classSec,
  onSelectClass,
  onSelectSection,
  moveRecessBackward,
  moveRecessForward,
  addPeriods,
  removePeriods,
  customDays,
  addDays,
  removeDays,
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [mssg, setMssg] = useState(false);

  const handleCloseDialog = () => {
    if (!onSelectClass || !onSelectSection) {
      setMssg(true);
      setDialogOpen(true);
    } else {
      setMssg(false);
      setDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  return (
    <Box>
      <Button onClick={handleOpenDialog} className="btn primaryBtn" variant="contained">Customize Structure</Button>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            color: "#000",
            justifyContent: "end",
          }}
        >
          <b>Customize</b>
        </DialogTitle>

        <DialogContent>
          <Box className="timeTblNoteCard">
            <Box>
              {mssg
                ? "You have not selected class and section please select"
                : ""}
              <Typography variant="h6">
                <b>Please select class and section.</b>
              </Typography>
              {mssg}
              <FormControl
                sx={{ m: 2, width: "150px" }}
                variant="standard"
                size="small"
              >
                <InputLabel>Select Class:</InputLabel>
                <Select
                  onChange={(e) => onSelectClass(e.target.value)}
                  label="Select Class"
                  name="class"
                  required
                >
                  {Array.from(new Set(classSec.map((obj) => obj.class).sort((a,b) => a - b))).map(
                    (data) => (
                      <MenuItem key={data} value={data}>
                        Class {data}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              <FormControl
                sx={{ m: 2, width: "150px" }}
                variant="standard"
                size="small"
              >
                <InputLabel>Select Section:</InputLabel>

                <Select
                  onChange={(e) => onSelectSection(e.target.value)}
                  label="Select Section"
                  name="section"
                  required
                >
                  {Array.from(new Set(classSec.map((obj) => obj.section).sort((a,b) => a.localeCompare (b)))).map(
                    (data) => {
                      return (
                        <MenuItem key={data} value={data}>
                          Section {data}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box className="btnsArea">
              <Typography variant="h6">
                <b>Change recess position if required.</b>
              </Typography>
              <Button
                onClick={moveRecessBackward}
                className="greenBtn btn"
                title="Move Left"
                sx={{
                  backgroundColor: "blueColor.background",
                  color: "blueColor.color",
                }}
              >
                <Box
                  className="iconSec"
                  sx={{ backgroundColor: "blueColor.color" }}
                >
                  <CiCircleChevLeft size={24} color="#fff" />
                </Box>
                Move Recess Left
              </Button>
              <Button
                onClick={moveRecessForward}
                className="greenBtn btn"
                title="Move Right"
                sx={{
                  backgroundColor: "blueColor.background",
                  color: "blueColor.color",
                }}
              >
                <Box
                  className="iconSec"
                  sx={{ backgroundColor: "blueColor.color" }}
                >
                  <CiCircleChevRight size={24} color="#fff" />
                </Box>
                Move Recess Right
              </Button>
            </Box>
            <Box className="btnsArea">
              <Typography variant="h6">
                <b>Add or delete period as per your requirement.</b>
              </Typography>
              <Button
                onClick={addPeriods}
                className="greenBtn btn"
                title="Add Period"
                sx={{
                  backgroundColor: "purpleColor.background",
                  color: "purpleColor.color",
                }}
              >
                <Box className="iconSec" sx={{ backgroundColor: "purpleColor.color" }}>
                  <IoAddCircleOutline size={24} color="#fff" />
                </Box>
                Add Period
              </Button>
              <Button
                onClick={removePeriods}
                className="greenBtn btn"
                title="Delete Period"
                sx={{
                  backgroundColor: "purpleColor.background",
                  color: "purpleColor.color",
                }}
              >
                <Box className="iconSec" sx={{ backgroundColor: "purpleColor.color" }}>
                  <AiOutlineMinusCircle size={24} color="#fff" />
                </Box>
                Delete Period
              </Button>
            </Box>
            <Box className="btnsArea">
              <Typography variant="h6">
                <b>Add or delete days as per your requirement.</b>
              </Typography>
              {customDays.length < 7 && (
                <Button
                  onClick={addDays}
                  className="greenBtn btn"
                  title="Add Days"
                  sx={{
                    backgroundColor: "orangeColor.background",
                    color: "orangeColor.color",
                  }}
                >
                  <Box
                    className="iconSec"
                    sx={{ backgroundColor: "orangeColor.color" }}
                  >
                    <IoAddCircleOutline size={24} color="#fff" />
                  </Box>
                  Add Days
                </Button>
              )}

              <Button
                onClick={removeDays}
                className="greenBtn btn"
                title="Delete Days"
                sx={{
                  backgroundColor: "orangeColor.background",
                  color: "orangeColor.color",
                }}
              >
                <Box className="iconSec" sx={{ backgroundColor: "orangeColor.color" }}>
                  <AiOutlineMinusCircle size={24} color="#fff" />
                </Box>
                Delete Days
              </Button>
            </Box>
          </Box>

          <Box textAlign={"center"} mt={2}>
            <Button onClick={handleCloseDialog} variant="contained" className="primaryBtn btn">
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TimTblStructure;
