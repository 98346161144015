import "./UserProfileDetails.scss";
import { Box, FormLabel, Grid, TextField, Typography } from "@mui/material";
import { languages } from "../../App";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FiEdit } from "react-icons/fi";
import ProfileBox from "./ProfileBox";
import ProfileImg from "./ProfileImg";
import ChangePassword from "../ChangePassword/ChangePassword";
import CustomSnackBar from "../../_Components/Common/SnackBar/CustomSnackBar";
import MainCard from "../../_Components/Common/Cards/MainCard";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

function UserProfileDetails() {
  let curLanguage = useContext(languages);
  const [profileInfo, setProfileInfo] = useState("");
  const [ContactFirst, setContactFirst] = useState("");
  const [ContactSecond, setContactSecond] = useState("");
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [saveImg, setSaveImg] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userinfoObject[0].id;
  const username = userinfoObject[0].id;
  let fullDate = "";
  const teacher = userinfoObject[0].department.toLowerCase() === "pedagogy";
  const student = userinfoObject[0].department.toLowerCase() === "student";
  const [updatedProfile, setUpdatedProfile] = useState();
  const [schoolName, setSchoolName] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getUserProfile?userid=${userId}`
      )
      .then((response) => {
        let dob = response?.data?.info?.[0]?.dob1;
        let cc = dob ? dob.split("-") : ["-", "-", "-"];
        fullDate = cc.length === 3 ? `${cc[2]}-${cc[1]}-${cc[0]}` : "- - -";
        setProfileInfo(response.data.info);
        setFormData({
          ...formData,
          gender: profileData.gender,
          dob: fullDate,
        });
      });
  }, []);

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getAllSchools`
        );

        const selectedSchoolInfo = response.data.info.find(
          (data) => data.id === Number(userinfoObject[0]?.institution)
        );
        setSchoolName(selectedSchoolInfo);
      } catch (error) {
        console.error("Error fetching school details:", error);
      }
    };

    fetchSchoolDetails();
  }, []);

  const profileData = profileInfo && profileInfo[0];

  //Edit Form Information Area

  const handleEditing = () => {
    setIsEditing(false);
  };

  const [formData, setFormData] = useState({
    firstname: profileData.firstname,
    lastname: profileData.lastname,
    phone1: profileData.phone1,
    phone2: profileData.phone2,
    gender: profileData.gender,
    dob: fullDate,
    city: profileData.city,
    state: profileData.state,
    country: profileData.country,
    address: profileData.address,
  });
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const saveEditing = async (event) => {
    event.preventDefault();
    const staticDateInfo = formData.dob.split("-");
    const staticDate = `${staticDateInfo[0]}-${staticDateInfo[1]}-${staticDateInfo[2]}`;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/updateUserProfile`,
        {
          userid: userId,
          firstname: formData.firstname || profileData.firstname,
          lastname: formData.lastname || profileData.lastname,
          phone1: ContactFirst || profileData.phone1,
          phone2: ContactSecond || profileData.phone2,
          address: formData.address || profileData.address,
          city: city || profileData.city,
          country: formData.country || profileData.country,
          dob: staticDate || fullDate,
          gender: formData.gender || profileData.gender,
          state: states || profileData.state,
        }
      );
      setUpdatedProfile(response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
    setIsEditing(true);
    setOpenSnackbar(true);
  };

  const backHandling = () => {
    setIsEditing(true);
  };

  const [profileImgInfo, setProfileImgInfo] = useState(userinfoObject[0]?.profileimageurl);
  const handleUpdateImg = ({updatedIMG}) => {
    setProfileImgInfo(updatedIMG)
    console.log(updatedIMG, "updatedIMG")
  }
 
  
  const handleImgUpload = () => {
    setSaveImg(true);
  };
  const handleContactChangeFirst = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setContactFirst(limitedValue);
  };
  const handleContactChangeSecond = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setContactSecond(limitedValue);
  };
  const handleCityNameChange = (event) => {
    const value = event.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    setCity(filteredValue);
  };
  const handleStateNameChange = (event) => {
    const value = event.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    setStates(filteredValue);
  };

  return (
    <Box className="profileWrapper">
      <MainCard
        className="profilecontent"
        mainPage={true}
        cardTitle="Manage Profile"
      >
        {profileInfo ? (
          <form onSubmit={saveEditing}>
            <Box className="profileBox">
              <Grid container className="profiler">
                <Grid item lg={1} md={1} sm={12} xs={12} className="imgBox">
                  <ProfileImg
                    isEditing={isEditing}
                    profileImgInfo={profileImgInfo}
                    userId={userId}
                    onImgUpload={handleImgUpload}
                    username={username}
                    onUpdatedProfileImgUrl={handleUpdateImg}
                  />
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12} className="imgBox">
                  <Box className="profileInformation">
                    <Typography variant="h4" className="heading">
                      {profileInfo[0].firstname} {profileInfo[0].lastname}
                    </Typography>
                    <Typography variant="h5">
                      School: {schoolName?.name}
                    </Typography>
                    <Box display={"flex"} mt={1}>
                      <Box width={"50%"}>
                        <FormLabel>Role</FormLabel>
                        <Typography>
                          {teacher ? "Teacher" : userinfoObject[0].department}
                        </Typography>
                      </Box>

                      <Box width={"50%"}>
                        <FormLabel>Last Access</FormLabel>
                        <Typography>{profileInfo[0].lastaccess}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box p={2}>
                <Typography variant="h6" mb={1}>
                  Personal Information
                </Typography>
                <Grid container className="profileInfo" spacing={2}>
                  {student && (
                    <ProfileBox>
                      <FormLabel>Roll number</FormLabel>
                      <Typography>{profileInfo[0].idnumber}</Typography>
                    </ProfileBox>
                  )}

                  <ProfileBox>
                    <FormLabel>{curLanguage.username}</FormLabel>
                    <Typography>{userinfoObject[0].username}</Typography>
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>{curLanguage.Emailaddress}</FormLabel>
                    <Typography>{userinfoObject[0].email}</Typography>
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>Joined on</FormLabel>
                    <Typography>{profileInfo[0].timecreated}</Typography>
                  </ProfileBox>

                  <ProfileBox>
                    <FormLabel>Gender</FormLabel>
                    <Typography>
                      {profileInfo[0].gender
                        ? (profileInfo[0].gender === "F" && "Female") ||
                          (profileInfo[0].gender === "M" && "Male")
                        : "Not Specified"}
                    </Typography>
                  </ProfileBox>

                  <ProfileBox>
                    <FormLabel>Contact 1</FormLabel>
                    {isEditing ? (
                      <Typography>
                        {ContactFirst ? ContactFirst : profileInfo[0].phone1}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].phone1}
                        name="phone1"
                        value={ContactFirst}
                        onChange={handleContactChangeFirst}
                        type="tel"
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>Contact 2</FormLabel>
                    {isEditing ? (
                      <Typography>
                        {ContactSecond ? ContactSecond : profileInfo[0].phone2}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].phone2}
                        name="phone2"
                        value={ContactSecond}
                        onChange={handleContactChangeSecond}
                        type="tel"
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>Address</FormLabel>
                    {isEditing ? (
                      <Typography>
                        {formData.address
                          ? formData.address
                          : profileInfo[0].address}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].address}
                        name="address"
                        value={formData.address}
                        onChange={onHandleChange}
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>{curLanguage.Citytown}</FormLabel>
                    {isEditing ? (
                      // <Typography>{profileInfo[0].city}</Typography>
                      <Typography>
                        {city ? city : profileInfo[0].city}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].city}
                        name="city"
                        value={city}
                        onChange={handleCityNameChange}
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>{curLanguage.state}</FormLabel>
                    {isEditing ? (
                      <Typography>
                        {states ? states : profileInfo[0].state}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].state}
                        name="state"
                        value={states}
                        onChange={handleStateNameChange}
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>{curLanguage.country}</FormLabel>
                    <Typography>
                      {profileInfo[0].country === "IN" && "India"}
                    </Typography>
                  </ProfileBox>
                </Grid>
              </Box>

              <Box className="subTexts">
                {isEditing ? (
                  <>
                    <PrimaryOutlineBtn
                      onClick={handleEditing}
                      startIcon={<FiEdit size={20} />}
                    >
                      {curLanguage.editprofile}
                    </PrimaryOutlineBtn>
                    <ChangePassword />
                  </>
                ) : (
                  <>
                    <PrimaryOutlineBtn onClick={backHandling}>
                      Back
                    </PrimaryOutlineBtn>
                    <PrimaryBgBtn type="submit">
                      {curLanguage.save}
                    </PrimaryBgBtn>
                  </>
                )}
              </Box>
            </Box>
          </form>
        ) : (
          ""
        )}
      </MainCard>
    </Box>
  );
}
export default UserProfileDetails;
