import { Box, Typography } from "@mui/material";
import { RiQuestionFill } from "react-icons/ri";

const InfoIcon = ({ mssg }) => {
  return (
    <Box display={"flex"} alignItems="center">
      <RiQuestionFill color={"primary.main"} size={24}/>
      <Typography><b>{mssg}</b></Typography>
    </Box>
  );
};

export default InfoIcon;
