import { Box } from "@mui/material";
import "./CircularLoader.scss";

const CircularLoader = () => {
  return (
    <Box className="row mt-2 loader-wrapper" id="loader-1">
      <Box id="loader" className="flex-center">
        <Box id="inner" className="flex-center"></Box>
      </Box>
    </Box>
  );
};

export default CircularLoader;
