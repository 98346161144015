import { fabric } from "fabric";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import io from "socket.io-client";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useEffect, useRef, useState } from "react";
import {
  DatePicker,
  DatePickerToolbar,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GrAttachment } from "react-icons/gr";
import { TbCertificate } from "react-icons/tb";
import axios from "axios";
import Carousel from "react-material-ui-carousel";
import { IoCloseCircleOutline } from "react-icons/io5";
import { languages } from "../../App";
import {
  canvasHelper,
  addImage,
  addSvgImage,
  addText,
  addNameId,
  loadJsonData,
  changeFontSize,
  changeFontFamily,
  changeTextColor,
  setTextAlignment,
} from "./canvasHelper";
import "./AddCertificate.scss";
import ColorPicker from "../../_Components/Utils/ColorPicker";
import {
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
  AiOutlineAlignRight,
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineStrikethrough,
  AiOutlineUnderline,
} from "react-icons/ai";
import { RxOverline } from "react-icons/rx";
import Loader from "../../_Components/Utils/loader/Loader";
import ProcessText from "../../_Components/Common/ProceessText";
import MainBackBtn from "../../_Components/Common/BackBtn/MainBackBtn";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { Scrollbar } from "react-scrollbars-custom";
import PreviousCertificates from "./PreviousCertificates";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddCertificate = () => {
  const token = localStorage.getItem("token");
  const nToken = localStorage.getItem("nToken");
  const theme = useTheme();
  const canvasRef = useRef(null);
  let curLanguage = useContext(languages);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const courseDetails = JSON.parse(localStorage.getItem("courseDetails"));
  let socketRef = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = useState(false);
  const [generatedUrl, setGeneratedUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isTempLoading, setIsTempLoading] = useState(true);
  const [currentCanvas, setCurrentCanvas] = useState(null);
  const [badges, setBadges] = useState();
  const [templateList, setTemplateList] = useState();
  const [adminCourseDetails, setAdminCourseDetails] = useState();
  const [uploadedImages, setUploadedImages] = useState({});
  const [certificates, setCertificates] = useState([]);

  const [fonts, setFonts] = useState({
    fontSize: 12,
    fontFamily: "Times New Roman",
    alignment: null,
  });
  const [certificateInfo, setCertificateInfo] = useState({
    class_sec: "",
    studentids: "",
    courseid: "",
    teacherid: "",
    templateid: "certificate-1",
    category: "",
    names: "",
    date: "",
    event: "",
    logo: "",
    signature: "",
    width: "",
    height: "",
    json: "",
    svg: "",
  });
  let width = 600;
  let height = 425;
  const [studentInfo, setStudentInfo] = useState([]);
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [naming, setNaming] = useState([]);
  const [classSec, setClassSec] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const classSection = selectedClass + selectedSection;
  const [dateTime, setDateTime] = useState(null);

  /* const minDateTime = dayjs();
  const maxDateTime = dayjs().endOf("year"); */

  const onHandleCategory = (event) => {
    const { name, value } = event.target;
    setCertificateInfo({ ...certificateInfo, [name]: value });
  };
  const handleSelectionChange = (event, selectionType) => {
    if (selectionType === "class") {
      setSelectedClass(event.target.value);
      setSelectedSection("");
      setSelectedSubject("");
    } else if (selectionType === "section") {
      setSelectedSection(event.target.value);
      setSelectedSubject("");
    } else if (selectionType === "subject") {
      setSelectedSubject(event.target.value);
      fetchData(selectedClass, selectedSection, event.target.value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedClass && selectedSection) {
          const selectedClassInfo = classSec.find(
            (item) =>
              item.class === parseInt(selectedClass) &&
              item.section === selectedSection
          );

          if (selectedClassInfo) {
            const subjectBaseURL = `${process.env.REACT_APP_nodewsPrefix}/getClassSubjects`;
            const response = await axios.post(
              subjectBaseURL,
              {
                class_name: selectedClass + selectedSection,
                schoolcode: userinfo[0]?.institution,
                group_name: selectedClassInfo.group_name,
              },
              {
                headers: {
                  Authorization: `Bearer ${nToken}`,
                },
              }
            );

            setAdminCourseDetails(response.data.subject_data);
            console.log(response.data, "datadatadatadatadata");
          }
        }
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };

    fetchData();
  }, [selectedClass, selectedSection, classSec]);

  const filterSubjects = (selectedClass, selectedSection) => {
    const courses = classSec
      .filter(
        (item) =>
          item.class === selectedClass && item.section === selectedSection
      )
      .map((item) => item.courseid);
    let filterSubjectData = [];
    if (userinfo[0]?.department.toLowerCase() === "admin") {
      filterSubjectData = adminCourseDetails;
    } else {
      filterSubjectData = courseDetails;
    }
    const subjectsList = filterSubjectData?.filter((data) =>
      courses.includes(data.id)
    );
    const shortNames = subjectsList?.filter(
      (data) => ProcessText(data.shortname).number === Number(selectedClass)
    );
    return shortNames;
  };

  function getSectionsByClass(selectedClass) {
    const classSection = classSec
      .filter((item) => item.class === selectedClass)
      .map((item) => item.section);

    const uniqueSections = [...new Set(classSection)];
    return uniqueSections;
  }

  const handleChangeStudents = (event) => {
    const selectedStudentNames = event.target.value;
    const selectedStudents = naming.filter((name) =>
      selectedStudentNames.includes(name)
    );

    const selectedStudentIDs = naming.filter(
      (_, index) =>
        index % 2 !== 0 && selectedStudentNames.includes(naming[index - 1])
    );

    setStudents(selectedStudents);
    setCertificateInfo((prevInfo) => ({
      ...prevInfo,
      names: selectedStudents,
      studentids: selectedStudentIDs,
      class_sec: classSection,
      courseid: selectedSubject,
      teacherid: userinfo[0]?.id,
    }));
  };

  const handleDelete = (nameToDelete) => {
    const updatedStudents = students.filter((name) => name !== nameToDelete);
    setStudents(updatedStudents);
    setCertificateInfo((prevInfo) => ({
      ...prevInfo,
      names: updatedStudents,
    }));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredStudents = studentInfo.filter((student) =>
    student.fullname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const uploadFile = (event) => {
    const id = event.target.id;
    if (event.target.src !== "") {
      addImage(event.target.src, id);
      return;
    }

    const formData = new FormData();

    formData.append(
      event.target.name,
      event.target.files[0],
      event.target.files[0].name
    );

    axios
      .post(`${process.env.REACT_APP_nodewsPrefix}/uploadFiles/${id}`, formData)
      .then((response) => {
        const newImages = { ...uploadedImages };
        newImages[id] = response.data.img;
        setUploadedImages(newImages);
        addImage(response.data.img, id);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const mssg = () => {
    return (
      <Alert severity="success">
        <AlertTitle>Certificate created successfully!</AlertTitle>
        <Button onClick={handleClickOpen}>view Certificate</Button>
      </Alert>
    );
  };

  const handleGenerateCertificate = (event) => {
    setIsLoading(true);
    const certificateURL = `${process.env.REACT_APP_nodewsPrefix}/createCertificates`;
    certificateInfo.json = JSON.stringify(currentCanvas.toJSON(["id"]));
    certificateInfo.svg = currentCanvas.toSVG();
    certificateInfo.width = currentCanvas.width;
    certificateInfo.height = currentCanvas.height;

    if (userinfo[0].department === "Developer") {
      axios
        .post(
          `${process.env.REACT_APP_nodewsPrefix}/saveCertificateDesign`,
          certificateInfo
        )
        .then((response) => {
          setIsLoading(false);
        });
      return;
    }

    axios
      .post(
        certificateURL,
        { ...certificateInfo, names: students },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      )
      .then((response) => {
        setGeneratedUrl(response.data);
        setIsLoading(false);
        mssg();

        let obj = { assignedby: userinfo[0].id };
        socketRef.current.emit("onNotification", {
          message: "Certificate",
          data: obj,
        });

        setSelectedClass("");
        setSelectedSection("");
        setSelectedSubject("");
        setStudents([]);

        setCertificateInfo((prevInfo) => ({
          ...prevInfo,
          names: [],
          category: "",
        }));
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_nodewsPrefix}/getTemplatesList`, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      })
      .then((response) => {
        setTemplateList(response.data.list);
        setIsTempLoading(false);
      });
    getBadges();

    fetchData();
    !currentCanvas && createCanvas();

    const classSecs = async () => {
      try {
        if (userinfo[0].department.toLowerCase() === "admin") {
          const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
          const response = await axios.get(baseURL, {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          });
          const data = response.data.class_section;

          setClassSec(data);
        } else {
          const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userinfo[0]?.id}&schoolcode=${userinfo[0]?.institution}`;
          const response = await axios.get(baseURL, {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          });
          setClassSec(response.data.rows);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchCertificates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_nodewsPrefix}/getTeacherAllcertificate?teacherid=${userinfo[0].id}`,
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );

        setCertificates(response.data.info);
      } catch (error) {
        console.error("Error fetching certificates:", error);
      }
    };

    classSecs();
    fetchCertificates();
    loadJsonData("certificate-1");

    socketRef.current = io(`${process.env.REACT_APP_nodewsPrefix}`);
    socketRef.current.on("connect", () => {
      socketRef.current.emit("newUserOnline", { userid: userinfo[0]?.id });
      console.log("newUserConnected..");
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  const fetchData = async (selectedClass, selectedSection, selectedSubject) => {
    if (!selectedSection || !selectedSubject || !selectedClass) {
      console.log("Please select class, section, and subject");
      return;
    }
    // let studentsData = null;
    try {
      const enroledURL = `${
        process.env.REACT_APP_wsPrefix
      }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${selectedSubject}&schoolcode=${Number(
        userinfo[0]?.institution
      )}&class=${selectedClass}&section=${selectedSection}`;

      const response2 = await axios.get(enroledURL);
      const studentDataObject = response2?.data;
      console.log(studentDataObject, "studentDataObject");
      const studentDataArray = Object.values(studentDataObject);
      const filteredStudents = studentDataArray.filter(
        (student) => student.department?.toLowerCase() === "student"
      );

      if (Array.isArray(filteredStudents)) {
        setStudentInfo(filteredStudents);
        filteredStudents.map((val) => {
          setNaming((prev) => [...prev, val.fullname, val.id]);
        });
      } else {
        console.error("Unexpected response data:", filteredStudents);
        setStudentInfo([]);
      }
    } catch (error) {
      console.error("Error fetching Student Info", error);
    }
  };

  const createCanvas = () => {
    let canvas = new fabric.Canvas(canvasRef.current, {
      width: width,
      height: height,
      selection: false,
      backgroundColor: "#ccc",
      enableRetinaScaling: true,
      preserveObjectStacking: true,
    });
    setCurrentCanvas(canvas);
    canvasHelper(canvas, width, height, setTextParameters, getTextColor);
  };

  const setTextParameters = (ff, fs, fa, fw, ffs, fu, lt, ol, fc) => {
    setFonts((prev) => ({
      ...prev,
      fontFamily: ff,
      fontSize: fs,
      alignment: fa,
      fontWeight: fw,
      fontStyle: ffs,
      underline: fu,
      linethrough: lt,
      overline: ol,
    }));
  };

  const getTextColor = (fun) => {
    // fun("#F8E71C");
  };

  const onHandleChange = (event) => {
    if (event.$D) {
      setDateTime(dayjs(event));
      addText(event.$D + "-" + (event.$M + 1) + "-" + event.$y, "date");
      return;
    }

    if (event.target !== undefined && event.target.name === "fontSize") {
      setFonts((prev) => ({
        ...prev,
        fontSize: event.target.value,
      }));
      changeFontSize(event.target.value);
      return;
    }

    if (event.target !== undefined && event.target.name === "fontFamily") {
      setFonts((prev) => ({
        ...prev,
        fontFamily: event.target.value,
      }));
      changeFontFamily(event.target.value);
    }
  };

  const addNewText = (e) => {
    addText("New Text", "new");
  };

  const handleAlignmentChange = (e, type, value) => {
    switch (type) {
      case "align":
        setFonts((prev) => ({
          ...prev,
          alignment: value,
        }));
        break;
      case "fontWeight":
        setFonts((prev) => ({
          ...prev,
          fontWeight: fonts.fontWeight === "bold" ? "normal" : value,
        }));
        break;
      case "fontStyle":
        setFonts((prev) => ({
          ...prev,
          fontStyle: fonts.fontStyle === "italic" ? "" : value,
        }));
        break;
      case "underline":
        setFonts((prev) => ({
          ...prev,
          underline: fonts.underline === true ? false : value,
        }));
        break;
      case "linethrough":
        setFonts((prev) => ({
          ...prev,
          linethrough: fonts.linethrough === true ? false : value,
        }));
        break;
      case "overline":
        setFonts((prev) => ({
          ...prev,
          overline: fonts.overline === true ? false : value,
        }));
        break;
      default:
        break;
    }

    setTextAlignment(type, value);
  };

  const downloadCertificate = async () => {
    /* const generatedUrl = await axios.get(`${process.env.REACT_APP_nodewsPrefix}/downloadCertificate`, {
      headers: {
        Authorization: `Bearer ${nToken}`,
      },
    });

    const link = document.createElement("a");
    link.href = generatedUrl.data.zip[0];
    link.download = ""//filename.replace(`${process.env.REACT_APP_nodewsPrefix}`, "");
    document.body.appendChild(link);
    link.click();
    console.log(generatedUrl.data, "*****NNO"); */
    // ====================================================================

    generatedUrl.zip.map(async (val, index) => {
      let filename = `${process.env.REACT_APP_nodewsPrefix}${generatedUrl.zip[index]}`;
      // filename = filename.replace(".png", ".zip")

      const link = document.createElement("a");
      console.log(filename, "*****NNO");
      link.href = filename;
      link.download = filename.replace(
        `${process.env.REACT_APP_nodewsPrefix}`,
        ""
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const getBadges = () => {
    axios
      .get(`${process.env.REACT_APP_nodewsPrefix}/getBadgesList`, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      })
      .then((response) => {
        setBadges(response.data);
      });
  };

  const changeTemplates = (e) => {
    setCertificateInfo((prevInfo) => ({
      ...prevInfo,
      templateid: e.currentTarget.id,
    }));
    setDateTime(null);
    loadJsonData(e.currentTarget.id);
  };

  const changeBadges = (e) => {
    addSvgImage(e.currentTarget.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const groupCertificatesByClassSec = (certificates) => {
    const groupedCertificates = {};

    certificates?.forEach((cert) => {
      const classsec = cert.classsec;
      if (!groupedCertificates[classsec]) {
        groupedCertificates[classsec] = [];
      }
      groupedCertificates[classsec].push(cert);
    });

    return groupedCertificates;
  };
  const sections = getSectionsByClass(selectedClass).sort();
  const groupedCertificates = groupCertificatesByClassSec(certificates);

  return (
    <Container maxWidth="xl" className="certificateSec">
      <Card
        sx={{
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
          position: "relative",
        }}
        elevation={5}
        className="customCard"
      >
        {isLoading && (
          <div className="loadingScreen">
            <Loader />
          </div>
        )}
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{
            borderColor: "borderLine.main",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box className="titleHead">
            <MainBackBtn />
            <b>{curLanguage.createcertificate}</b>
          </Box>

          <Box display={"flex"} gap={1}>
            {!isLoading && (
              <>
                {generatedUrl && (
                  <Tooltip title={curLanguage.viewcertificate}>
                    <PrimaryBgBtn
                      onClick={handleClickOpen}
                      startIcon={<TbCertificate size={24} />}
                    >
                      {curLanguage.viewcertificate}
                    </PrimaryBgBtn>
                  </Tooltip>
                )}
              </>
            )}
            <PreviousCertificates groupedCertificates={groupedCertificates} />
          </Box>
        </Typography>

        <Box p={2} className="customCardContent">
          <Grid container spacing={2}>
            {/* Certificate Accordion Section Start */}
            <Grid item md={3}>
              <Accordion
                defaultExpanded
                className="templateAccordion"
                sx={{ backgroundColor: "template.bgColor" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "template.color",
                        borderColor: "template.borderColor",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="accordion-head"
                  sx={{ backgroundColor: "template.headColor" }}
                >
                  <Typography sx={{ color: "template.color" }}>
                    Certificate Design
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="templateAccordionContent">
                  {isTempLoading ? (
                    <Loader />
                  ) : (
                    templateList?.map((val) => {
                      return (
                        <Button
                          onClick={changeTemplates}
                          id={val.id}
                          key={val.id}
                          sx={{ padding: "0" }}
                        >
                          <img
                            width="100"
                            src={val.url}
                            alt="certificate-template"
                          />
                        </Button>
                      );
                    })
                  )}
                </AccordionDetails>
              </Accordion>

              {/* For templates and design inputs from frontend */}
              {userinfo[0].department === "Developer" && (
                <Accordion
                  className="templateAccordion"
                  sx={{ backgroundColor: "template.bgColor" }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: "template.color",
                          borderColor: "template.borderColor",
                        }}
                      />
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="accordion-head"
                    sx={{ backgroundColor: "template.headColor" }}
                  >
                    <Typography sx={{ color: "template.color" }}>
                      Fields
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="templateAccordionContent">
                    <Button className="btn">Create New Certificate</Button>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Set Name"
                        onChange={(e) => addNameId(e.target.checked)}
                      />
                    </FormGroup>
                    <TextField
                      type="file"
                      name="file"
                      fullWidth
                      margin="normal"
                      inputProps={{
                        accept: "image/png, image/jpeg",
                      }}
                      // onChange={uploadFile}
                      id="newBg"
                      sx={{ m: 0, display: "none" }}
                    />
                    <label htmlFor="newBg" className="attachLabel">
                      <GrAttachment style={{ color: "iconColor.color" }} />
                      <span>Change Background Image</span>
                    </label>
                  </AccordionDetails>
                </Accordion>
              )}
              {/* For templates and design inputs from frontend Ends*/}
            </Grid>
            {/* Certificate Accordion Section  End*/}
            {/* Certificate Template Section Start */}
            <Grid item lg={6} className="templateSec">
              <Box className="templateCard">
                <canvas
                  ref={canvasRef}
                  style={{
                    borderColor: "lightBg.textColor",
                    padding: "6px",
                  }}
                />
              </Box>
              <Button
                id="deleteBtn"
                sx={{ position: "absolute", display: "none" }}
              >
                <IoCloseCircleOutline />
              </Button>
            </Grid>
            {/* Certificate Template Section  End*/}

            <Grid item md={3} className="textSec">
              <Scrollbar style={{ width: "100%", height: `calc(46vh - 15px)` }}>
                <Card className="certificateData">
                  <CardContent sx={{ pt: "2" }}>
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={12}>
                        <Box>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"en-gb"}
                          >
                            <DatePicker
                              label="Date"
                              onChange={onHandleChange}
                              name="date"
                              sx={{ width: "100%" }}
                              size="small"
                              value={dateTime}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                      {/* Add class selection */}
                      <Grid item md={6} sm={12}>
                        <FormControl fullWidth>
                          <InputLabel id="class-select-label">Class</InputLabel>
                          <Select
                            labelId="class-select-label"
                            id="class-select"
                            value={selectedClass}
                            input={<OutlinedInput label="class" />}
                            onChange={(event) =>
                              handleSelectionChange(event, "class")
                            }
                          >
                            {Array.from(
                              new Set(
                                classSec
                                  .map((obj) => obj.class)
                                  .sort((a, b) => a - b)
                              )
                            ).map((data, index) => (
                              <MenuItem key={index} value={data}>
                                Class {data}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <FormControl fullWidth>
                          <InputLabel id="section-select-label">
                            Section
                          </InputLabel>
                          <Select
                            labelId="section-select-label"
                            id="section-select"
                            value={selectedSection}
                            input={<OutlinedInput label="section" />}
                            onChange={(event) =>
                              handleSelectionChange(event, "section")
                            }
                          >
                            {Array.from(
                              new Set(
                                sections.map((value) => (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                ))
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Add section selection */}
                      <Grid item md={6} sm={12}>
                        <FormControl fullWidth>
                          <InputLabel id="subject-select-label">
                            Subject
                          </InputLabel>
                          <Select
                            labelId="subject-select-label"
                            id="subject-select"
                            value={selectedSubject}
                            input={<OutlinedInput label="Subject" />}
                            onChange={(event) =>
                              handleSelectionChange(event, "subject")
                            }
                          >
                            {filterSubjects(
                              selectedClass,
                              selectedSection
                            )?.map((data) => (
                              <MenuItem
                                key={data.id ? data.id : data.courseid}
                                value={data.id ? data.id : data.courseid}
                              >
                                {data.fullname
                                  ? data.fullname
                                  : data.course_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={12} sm={12}>
                        <FormControl
                          fullWidth
                          className={
                            !certificateInfo.names.length > 0 ? "error" : ""
                          }
                        >
                          <InputLabel id="demo-multiple-checkbox-label">
                            Student
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            name="names"
                            value={students}
                            onChange={handleChangeStudents}
                            input={<OutlinedInput label="Student" />}
                            isSearchable
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    onDelete={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      handleDelete(value);
                                    }}
                                    onMouseDown={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                            required
                          >
                            <MenuItem>
                              <TextField
                                value={searchQuery}
                                onChange={handleSearchChange}
                                placeholder="Search..."
                                fullWidth
                                variant="outlined"
                                size="small"
                                margin="dense"
                              />
                            </MenuItem>
                            {filteredStudents.map((student) => (
                              <MenuItem
                                key={student.id}
                                value={student.fullname}
                              >
                                <Checkbox
                                  checked={
                                    students.indexOf(student.fullname) > -1
                                  }
                                />
                                <ListItemText primary={student.fullname} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextField
                          type="text"
                          name="category"
                          value={certificateInfo.category}
                          onChange={onHandleCategory}
                          fullWidth
                          margin="0"
                          label="Category"
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Button
                          variant="contained"
                          color="secondaryColor"
                          id="titleText"
                          onClick={addNewText}
                        >
                          Add Text
                        </Button>
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextField
                          type="file"
                          name="file"
                          fullWidth
                          margin="normal"
                          required
                          inputProps={{
                            accept: "image/png, image/jpeg",
                          }}
                          onChange={uploadFile}
                          id="signature"
                          sx={{ m: 0, display: "none" }}
                        />
                        <label htmlFor="signature" className="attachLabel">
                          <GrAttachment style={{ color: "iconColor.color" }} />
                          <span>Add signature</span>
                        </label>
                        {uploadedImages.signature && (
                          <img
                            src={uploadedImages.signature}
                            alt="signature"
                            className="uploadedSign"
                            onClick={uploadFile}
                          />
                        )}
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextField
                          type="file"
                          name="file"
                          fullWidth
                          margin="normal"
                          required
                          inputProps={{
                            accept: "image/png, image/jpeg",
                          }}
                          onChange={uploadFile}
                          id="logo"
                          sx={{ m: 0, display: "none" }}
                        />
                        <label htmlFor="logo" className="attachLabel">
                          <GrAttachment />
                          <span>Add Logo</span>
                        </label>
                        {uploadedImages.logo && (
                          <img
                            src={uploadedImages.logo}
                            alt="logo"
                            className="uploadedLogo"
                            onClick={uploadFile}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Scrollbar>
            </Grid>

            {/* Certificate Text Fields Section end */}
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Card className="certificateData">
                <CardContent>
                  <Typography variant="p" display={"block"}>
                    <b>Fonts</b>
                  </Typography>
                  <Grid container spacing={2} className="fontSec">
                    <Grid item xs={2}>
                      <label>Size</label>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="fontSize"
                        value={fonts.fontSize}
                        onChange={onHandleChange}
                        size="small"
                      >
                        {[
                          12, 14, 16, 18, 20, 22, 24, 28, 36, 40, 44, 48, 52,
                        ].map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={2}>
                      <label>Family</label>
                      <br />
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="fontFamily"
                          value={fonts.fontFamily}
                          onChange={onHandleChange}
                          size="small"
                        >
                          <MenuItem
                            value="Times New Roman"
                            sx={{ fontFamily: "Times New Roman" }}
                          >
                            Times New Roman
                          </MenuItem>
                          <MenuItem
                            value="Style Script"
                            sx={{
                              fontFamily: "Style Script",
                              fontSize: "20px",
                            }}
                          >
                            Style Script
                          </MenuItem>
                          <MenuItem
                            value="Merriweather"
                            sx={{ fontFamily: "Merriweather" }}
                          >
                            Merri weather
                          </MenuItem>
                          <MenuItem
                            value="Roboto"
                            sx={{ fontFamily: "Roboto" }}
                          >
                            Roboto
                          </MenuItem>
                          <MenuItem
                            value="Poppins"
                            sx={{ fontFamily: "Poppins" }}
                          >
                            Poppins
                          </MenuItem>
                          <MenuItem
                            value="Open Sans"
                            sx={{ fontFamily: "Open Sans" }}
                          >
                            Open Sans
                          </MenuItem>
                          <MenuItem
                            value="Young Serif"
                            sx={{ fontFamily: "Young Serif" }}
                          >
                            Young Serif
                          </MenuItem>
                          <MenuItem
                            value="Archivo Black"
                            sx={{ fontFamily: "Archivo Black" }}
                          >
                            Archivo Black
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <label>Color</label>
                      <br />
                      <Button sx={{ p: "0" }}>
                        <ColorPicker
                          func={changeTextColor}
                          cColor={getTextColor}
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <label>Alignment</label>
                      <br />
                      <ButtonGroup className="align">
                        <Button
                          onClick={(e) =>
                            handleAlignmentChange(e, "align", "left")
                          }
                          className={
                            fonts.alignment === "left" ? "selected" : ""
                          }
                        >
                          <AiOutlineAlignLeft />
                        </Button>
                        <Button
                          onClick={(e) =>
                            handleAlignmentChange(e, "align", "center")
                          }
                          className={
                            fonts.alignment === "center" ? "selected" : ""
                          }
                        >
                          <AiOutlineAlignCenter />
                        </Button>
                        <Button
                          onClick={(e) =>
                            handleAlignmentChange(e, "align", "right")
                          }
                          className={
                            fonts.alignment === "right" ? "selected" : ""
                          }
                        >
                          <AiOutlineAlignRight />
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    <Grid item xs={3}>
                      <label>Styles</label>
                      <br />
                      <ButtonGroup className="align">
                        <Button
                          onClick={(e) =>
                            handleAlignmentChange(e, "fontWeight", "bold")
                          }
                          className={
                            fonts.fontWeight === "bold" ? "selected" : ""
                          }
                        >
                          <AiOutlineBold />
                        </Button>
                        <Button
                          onClick={(e) =>
                            handleAlignmentChange(e, "fontStyle", "italic")
                          }
                          className={
                            fonts.fontStyle === "italic" ? "selected" : ""
                          }
                        >
                          <AiOutlineItalic />
                        </Button>

                        <Button
                          onClick={(e) =>
                            handleAlignmentChange(e, "underline", true)
                          }
                          className={fonts.underline === true ? "selected" : ""}
                        >
                          <AiOutlineUnderline />
                        </Button>
                        <Button
                          onClick={(e) =>
                            handleAlignmentChange(e, "linethrough", true)
                          }
                          className={
                            fonts.linethrough === true ? "selected" : ""
                          }
                        >
                          <AiOutlineStrikethrough />
                        </Button>
                        <Button
                          onClick={(e) =>
                            handleAlignmentChange(e, "overline", true)
                          }
                          className={fonts.overline === true ? "selected" : ""}
                        >
                          <RxOverline />
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <Box textAlign={"center"} mb={2} className="btnArea">
          {userinfo[0].department === "Developer" ? (
            <PrimaryBgBtn onClick={handleGenerateCertificate}>
              Save
            </PrimaryBgBtn>
          ) : (
            <PrimaryBgBtn
              onClick={
                !certificateInfo.names.length > 0 ||
                !certificateInfo.templateid?.length > 0
                  ? ""
                  : handleGenerateCertificate
              }
              className={`btn primaryBtn ${
                !certificateInfo.names.length > 0 ||
                !certificateInfo.templateid?.length > 0
                  ? "notSelected"
                  : ""
              }`}
            >
              {curLanguage.createcertificate}
            </PrimaryBgBtn>
          )}

          <div className="errorBox">
            <Box
              className="errorMssg"
              sx={{
                backgroundColor: "secondaryColorOutline.main",
                color: "secondaryColorOutline.contrastText",
                borderColor: "secondaryColorOutline.border",
              }}
            >
              Please select template and student to move forward.
            </Box>
          </div>
        </Box>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogContent
            sx={{
              px: "3",
              py: "3",
              backgroundColor: "cardColor.main",
              color: "cardColor.contrast",
            }}
          >
            {generatedUrl && (
              <PrimaryBgBtn
                onClick={downloadCertificate}
                style={{ position: "absolute", right: "15px", zIndex: "2" }}
              >
                Download Certificate
              </PrimaryBgBtn>
            )}

            <Carousel
              navButtonsAlwaysVisible={true}
              indicators={false}
              autoPlay={false}
              sx={{ height: "88vh" }}
            >
              {generatedUrl &&
                generatedUrl.success &&
                generatedUrl.url.map((val) => {
                  return (
                    <Box p={6}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        key={val}
                        sx={{ maxHeight: "80vh" }}
                      >
                        <img
                          src={
                            open ? process.env.REACT_APP_nodewsPrefix + val : ""
                          }
                          alt="Generated Certificate"
                          style={{ maxHeight: "80vh" }}
                        />
                      </Box>
                    </Box>
                  );
                })}
            </Carousel>
          </DialogContent>
        </Dialog>
      </Card>
    </Container>
  );
};

export default AddCertificate;
