export const AdmissionInfo = [
  {
    id: 0,
    registrationNo: "123",
    firstName: "Lorem 2",
    lastName: "Ipsum",
    class: "6",
    dob: "01/01/2000",
    birthcertificate: "https://",
    bloodGroup: "O+",
    gender: "male",
    userImgfile: "https://",
    nationality: "Indian",
    religion: "Hindu",
    category: "General",
    ews: "checked",
    ewsfile: "https://",
    siblingInfo: "checked",
    transportInfo: "unchecked",
    admissionno: "1234",
    siblingname: "sibling Lorem",
    siblingclass: "12",
    section: "A",
    feereceipt: "25896",
    guardian: "guardianNAme",
    relation: "Father",
    lastschool: "School NAME",
    schooladdress: "School Address",
    physically: "unchecked",
    mentally: "checked",
    illness: "checked",
    descriptionIllness: "illness explanation",
    salutation: "Mr",
    fullname: "Lorem Ipsum",
    highqualification: "PHD",
    occupation: "Developer",
    annualIncome: "10L",
    officeAddress: "Lorem Ipsum text",
    phoneNumber: "+0120 2222 222",
    mobile: "9999999999",
    contactNo: "9999999999",
    email: "b@gmail.com",
    panfile: "https://",
    aadharfile: "https://",
    salutation2: "Mrs",
    fullname2: "Lorem Ipsum",
    highqualification2: "PHD",
    occupation2: "Developer",
    annualIncome2: "10L",
    officeAddress2: "Lorem Ipsum text",
    phoneNumber2: "+0120 2222 222",
    mobile2: "9999999999",
    contactNo2: "9999999999",
    email2: "b@gmail.com",
    panfile2: "https://",
    aadharfile2: "https://",
    addressl1: "Address Line 1",
    addressl2: "Address Line 2",
    ccountry: "India",
    cstate: "Uttar Pradesh",
    ccity: "Greater Noida",
    cpincode: "201306",
    paddressl1: "Address Line 1",
    paddressl2: "Address Line 2",
    pcountry: "India",
    pstate: "Uttar Pradesh",
    ppcity: "Greater Noida",
    ppincode: "201306",
  }
];

