import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import MainCard from "../../Common/Cards/MainCard";
import { PrimaryBgBtn } from "../../Common/Buttons/CustomBtns";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const AssignRole = () => {
  const [formData, setFormData] = useState([]);
  const [formValue, setFormValue] = useState({
    role: "",
    shortname: "",
    description: "",
    addpermission: "",
    duration: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const handleInputData = (event) => {
    const { name, value } = event.target;
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (isEditing) {
      setFormData((prev) =>
        prev.map((data) =>
          data.id === currentId ? { ...formValue, id: currentId } : data
        )
      );
      setIsEditing(false);
      setCurrentId(null);
    } else {
      setFormData((prev) => [...prev, { ...formValue, id: prev.length + 1 }]);
    }
    setFormValue({
      role: "",
      shortname: "",
      description: "",
      addpermission: "",
      duration: "",
    });
  };

  const handleFormEdit = (id) => {
    const editData = formData.find((data) => data.id === id);
    setFormValue(editData);
    setIsEditing(true);
    setCurrentId(id);
  };

  const handleFormDelete = (id) => {
    setFormData((prev) => prev.filter((data) => data.id !== id));
  };

  const handleDateChange = (date) => {
    setFormValue((prev) => ({ ...prev, duration: date }));
  };

  return (
    <>
      <MainCard cardTitle={"Assign User"}>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item md={5} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="role"
                    label="Username"
                    variant="outlined"
                    name="role"
                    value={formValue.role}
                    onChange={handleInputData}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="shortname"
                    label="Department"
                    variant="outlined"
                    name="shortname"
                    value={formValue.shortname}
                    onChange={handleInputData}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="addpermission"
                    label="Additional Permission"
                    variant="outlined"
                    name="addpermission"
                    value={formValue.addpermission}
                    onChange={handleInputData}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      label="Duration"
                      value={formValue.duration}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      format="DD-MM-YYYY"
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={7} sm={12} xs={12}>
              <TextField
                id="description"
                label="Description"
                name="description"
                value={formValue.description}
                onChange={handleInputData}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box textAlign={"center"} mt={2}>
            <PrimaryBgBtn type="submit">
              {isEditing ? "Update" : "Save"}
            </PrimaryBgBtn>
          </Box>
        </form>
      </MainCard>
      {formData.length > 0 && (
        <Box mt={2}>
          <MainCard>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Additional Permission</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.map((data) => (
                    <TableRow
                      key={data.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {data.role}
                      </TableCell>
                      <TableCell align="left">{data.shortname}</TableCell>
                      <TableCell align="left">{data.description}</TableCell>
                      <TableCell align="left">{data.addpermission}</TableCell>
                      <TableCell align="left">
                        {data.duration
                          ? dayjs(data.duration).format("MM/DD/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={() => handleFormEdit(data.id)}
                          aria-label="edit"
                          title="Edit"
                        >
                          <FiEdit size={20} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleFormDelete(data.id)}
                          aria-label="delete"
                          title="Delete"
                        >
                          <RiDeleteBin6Line size={20} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MainCard>
        </Box>
      )}
    </>
  );
};

export default AssignRole;
