import { Box, Grid, IconButton, Typography } from "@mui/material";
import CustomMenu from "../../_Components/Common/CustomMenu";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import io from "socket.io-client";
import { languages } from "../../App";
import { useContext, useEffect, useRef, useState } from "react";
import "./Notification.scss";
import { GrCertificate } from "react-icons/gr";
import { Scrollbar } from "react-scrollbars-custom";


const Notification = () => {
  let curLanguage = useContext(languages);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [notification, setNotification] = useState();
  const navigate = useNavigate();
  const [messages, setmessages] = useState([]);
  let [notiId, setNotiId] = useState(0);
  let socketRef = useRef(null);
  const nToken = localStorage.getItem("nToken");

  useEffect(() => {
    if (userinfo && userinfo[0]?.id) {
      axios.get(`${process.env.REACT_APP_nodewsPrefix}/getNotification?studentid=${userinfo[0]?.id}`, {
            headers: {
              Authorization: `Bearer ${nToken}`
            }
          })
        .then((response) => {
          setNotification(response.data);
        });

      socketRef.current = io(`${process.env.REACT_APP_nodewsPrefix}`);
      socketRef.current.on("newUserConnected", () => {
        console.log("newUserConnected..");
      });

      socketRef.current.on("onMessage", (message, callBack) => {
        console.log(message, "NNO");
        setNotiId(notiId += 1);
      });

      return () => {
        socketRef.current.disconnect();
      };
    }else {
      localStorage.clear()
    }

  }, [notiId]);

  const navigateToComponent = (e) => {
    console.log(e.currentTarget.getAttribute("comp"), "NNO ", e.currentTarget.id);
    let component = "";
    switch (e.currentTarget.getAttribute("comp")) {
      case "certificate":
        component = "stdcertificate";
        break;    
      default:
        break;
    }

    axios.get(`${process.env.REACT_APP_nodewsPrefix}/markNotificationRead?studentid=${userinfo[0].id}&notificationid=${e.currentTarget.id}`, {
      headers: {
        Authorization: `Bearer ${nToken}`
      }
    })
      .then((response) => {
        setNotiId(notiId += 1);
        navigate("/"+component);
      });
  };

  return (
    <CustomMenu
      imgName="notification.png"
      altTxt="notification"
      imgSize={40}
      tooltipTitle="Notification"
      iconClass="notification circleBtn"
      certCount={notification?.length}
    >
      <Box className="notificationWrapper">
        <Typography variant="h6" className="heading" textAlign={"center"}>
          Notifications
        </Typography>
        <Box className="notificationContent">
          <Scrollbar style={{ width: "100%", height: "65vh" }}>
            {notification?.length ? (
              notification?.map((val, index) => {
                return (
                  <Box onClick={navigateToComponent} key={index} id={val.id} comp={val.component} className="notificationBox">
                    <IconButton>
                      <GrCertificate />
                    </IconButton>
                    <Typography variant="body1">
                      You have earned a certificate for {val.subject}
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <Box>
                <Typography textAlign={"center"}>
                  No new notifications.
                </Typography>
              </Box>
            )}
          </Scrollbar>
        </Box>
      </Box>
    </CustomMenu>
  );
};

export default Notification;
