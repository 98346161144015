import { Box, Grid } from "@mui/material";
import PrincipalCards from "./PincipalCards";
import "./PincipalCards.scss";

const PrincipalDashboard = () => {
  return (
    <Box className="principalDashboard">
      <Grid container spacing={2}>
        <PrincipalCards
          links="/user-reports"
          customclass="report"
          icons="report.png"
          text="Report"
        />
        <PrincipalCards
          links="/adminAttendance"
          customclass="attendance"
          icons="attendance.svg"
          text="Attendance"
        />
         <PrincipalCards
          links="/notes"
          customclass="notes"
          icons="notes.png"
          text="Notes"
        />
        <PrincipalCards
          links="/user-certificate"
          customclass="certificate"
          icons="certificate.svg"
          text="Certificate"
        />
      </Grid>
    </Box>
  );
};

export default PrincipalDashboard;
