import { Box, Typography } from "@mui/material";

const SliderContent = ({
  sliderImg,
  content,
  altTxt,
  customClass,
  subText,
}) => {
  return (
    <Box className="sliderSection">
      <img
        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/slider/${sliderImg}`}
        alt={altTxt}
      />
      <Box className="contentContainer">
        <Box className={customClass}>
          <Typography variant="h1" className="heading">
            {content}
          </Typography>
          {subText && (
            <Typography variant="h3" className="subHeading">
              {subText}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SliderContent;
