import {
  ADD_DATA,
  MYCOURSE_DATA,
  REMOVE_DATA,
  CLASSSEC_DATA,
} from "../Constants";

export const addData = (data) => {
  return {
    type: ADD_DATA,
    data: data,
  };
};

export const addClassSec = (data) => {
  console.log("action addCourseData: ", data);
  return {
    type: CLASSSEC_DATA,
    data: data,
  };
};

export const addCourseData = (data) => {
  console.log("action addCourseData: ", data);
  return {
    type: MYCOURSE_DATA,
    data: data,
  };
};

export const removeData = (data) => {
  return {
    type: REMOVE_DATA,
    data: data,
  };
};
