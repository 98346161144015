import {  useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    const scrollableContent = document.querySelector('.contentWrapper');

    if (scrollableContent) {
      scrollableContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;