import { Box, Typography } from "@mui/material";
import "./ListingCard.scss";

const ListingCard = ({ img, title, children, imgAltTxt }) => {
  return (
    <Box className="outerLayer">
      {img && <img src={img} alt={imgAltTxt} />}
      <Typography className="tile">{title}</Typography>
      <Typography className="subtitle">{children}</Typography>
    </Box>
  );
};

export default ListingCard;
