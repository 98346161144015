import React from "react";

const UploadImage = ({ mssg }) => {
  return (
    <img
      src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/upload.png`}
      alt="uploadImg"
      width={52}
      title={mssg}
      
    />
  );
};

const DownloadImage = ({ mssg }) => {
  return (
    <img
      src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/download.png`}
      alt="downloadImg"
      width={52}
      title={mssg}
      
    />
  );
};

export { UploadImage, DownloadImage };
