import { Button, Snackbar, SnackbarContent } from "@mui/material";
import { useState } from "react";

const CustomSnackBar = ({ message, open, setOpen }) => {
  const handleSnackbarClose = () => {
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <SnackbarContent
        sx={{ backgroundColor: "greenColor.color" }}
        message={message}
        action={
          <Button color="inherit" size="small" onClick={handleSnackbarClose}>
            OK
          </Button>
        }
      />
    </Snackbar>
  );
};

export default CustomSnackBar
