import { useNavigate } from "react-router-dom";
import "./BackBtn.scss";
import { IconButton } from "@mui/material";

const BackButton = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <IconButton onClick={goBack} sx={{ padding: 0}}>
      <img
        className="goBack"
        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/backBtn.png`}
        alt="icon"
        width={45}
        style={{
          fontSize: "14px",
          minHeight: "45px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    </IconButton>
  );
};

export default BackButton;
