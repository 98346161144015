import { CircularProgress, Grid } from "@mui/material";
import React, { useState } from "react";

const Loader = (props) => {
  const [isLoader, setIsLoader] = useState(true);

  const onPopupClose = () => {};

  return (
    <Grid container sx={{ justifyContent: "center" }}>
      {/* <CircularProgress color="buttons" size={50} thickness={4} /> */}
      <img
        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/circularloader.gif`}
        alt="loader"
        width={"45px"}
      />
    </Grid>
  );
};
export default Loader;
