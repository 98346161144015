import EnglishLanguage from "./language/English";
import GermanLanguage from "./language/German";
import HindiLanguage from "./language/Hindi";

const LanguagePack = {
  English: EnglishLanguage,
  Hindi: HindiLanguage,
  German: GermanLanguage,
};

export default LanguagePack;
