import { Box, Card, Container, Typography } from "@mui/material";
import BackButton from "../BackBtn/BackBtn";
import { Scrollbar } from "react-scrollbars-custom";
import MainBackBtn from "../BackBtn/MainBackBtn";

const MainCard = ({
  cardTitle,
  children,
  additionalData,
  customClass,
  mainPage,
  mt,
}) => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: mt ? mt : "" }}>
      <Box className={`${customClass ? customClass : ""}`}>
        <Card
          sx={{
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
            position: "relative",
          }}
          elevation={5}
          className="customCard"
        >
          {cardTitle && (
            <Typography
              className="title"
              variant="h5"
              px={2}
              py={1}
              sx={{
                borderColor: "borderLine.main",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="titleHead">
                {mainPage ? <MainBackBtn /> : <BackButton />}
                <b>{cardTitle}</b>
              </Box>
              {additionalData}
            </Typography>
          )}

          <Box p={2} className="customCardContent">
            {/* <Scrollbar
            style={{ width: "100%", height: 671 }}
            className="customCardItem"
          > */}
            {children}
            {/* </Scrollbar> */}
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default MainCard;
