import { Box, DialogContent } from "@mui/material";

const ComingSoon = () => {
  return (
    <DialogContent>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <img
          src={`${process.env.REACT_APP_wsPrefix}lms-assets/images/coming-soon.png`}
          alt="coming soon"
          maxWidth={"100%"}
        />
      </Box>
    </DialogContent>
  );
};

export default ComingSoon;
