import React, { Children, Component } from 'react';
import "./IconWithText.css";

import AdjustIcon from '@mui/icons-material/Adjust';
import QuizTwoToneIcon from "@mui/icons-material/QuizTwoTone"; //Quiz
import MotionPhotosAutoTwoToneIcon from "@mui/icons-material/MotionPhotosAutoTwoTone"; // Animation
import AddTaskTwoToneIcon from "@mui/icons-material/AddTaskTwoTone"; // Animated Activity
import DocumentScannerTwoToneIcon from "@mui/icons-material/DocumentScannerTwoTone"; // Slideshow
import GTranslateTwoToneIcon from "@mui/icons-material/GTranslateTwoTone"; //Dictionary
import AutoStoriesTwoToneIcon from "@mui/icons-material/AutoStoriesTwoTone"; // Ebook
import PhoneIcon from '@mui/icons-material/Phone'; // Phone
import MailIcon from '@mui/icons-material/Mail'; // Mail

import ScheduleIcon from '@mui/icons-material/Schedule';// Schedule
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';// Calendar
import DescriptionIcon from '@mui/icons-material/Description'; // Description
import { Tag } from '@mui/icons-material';

class IconWithText extends Component {
    constructor(props) {
        super();
    }
    components = {
        "default": AdjustIcon,
        "quiz": QuizTwoToneIcon,
        "h5pactivity": MotionPhotosAutoTwoToneIcon,
        "scorm": AddTaskTwoToneIcon,
        "slideshow": DocumentScannerTwoToneIcon,
        "dictionary": GTranslateTwoToneIcon,
        "ebook": AutoStoriesTwoToneIcon,
        "phone": PhoneIcon,
        "mail": MailIcon,
        "Schedule": ScheduleIcon,
        "calendar": CalendarMonthIcon,
        "description": DescriptionIcon
    };
    render() {
        let TagName = this.components[this.props.tag || 'default'];
        TagName = TagName || this.components['default'];

        let classses = this.props.bg ? "iwt-icon-wrap iwt-gray-bg-shadow" : "iwt-icon-wrap";

        const data = this.props.children
        return <>
        <span className={classses}><TagName /></span> 
        {this.props.Link && this.props.Link.length ? <a href={this.props.Link}> {this.props.text} </a> : this.props.text} {this.props.children}
    </>

    }
}

export default IconWithText;