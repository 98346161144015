import React from 'react';
import { Grid, Typography,  Paper, List, ListItem } from '@mui/material';
const GuidelinesForParents=()=>{
    const guidelines = [
        "* This progress profile is a gradual and comprehensive assessment of your child's personality and his/her physical & emotional development.",
        "* It is very important that your ward comes to school regularly as it helps us to assess the child more comprehensively.",
        "* The Scholastic Term Assessment will be reflected in the Progress Profile in the form of grades on an 8 point scale.",
        "* Criteria based scholastic skills as Scholastic Profile will be graded on a 6 Point Grading Scale.",
        "* Criteria based co-scholastic skills as Co-scholastic Profile will be graded on a 4 Point Grading Scale.",
        "* This report card is an important document and should be carefully perused & sent back to school duly signed, within two days of its receipt."
      ];
return(
    <>
     <Grid container justifyContent="center" >
          <Grid item xs={12} md={10}>
            <Paper variant="outlined" >
              <Grid container>
                <Grid item xs={12} >
                  <Typography alignItems='center' variant="h4">GUIDELINES FOR THE PARENTS</Typography>
                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={12}>
                  <List>
                    {guidelines.map((guideline, index) => (
                      <ListItem key={index} >
                        {guideline}
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
    </>
)
}
export default GuidelinesForParents
