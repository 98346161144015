import Grid from '@mui/material/Grid';
import './AssetsBox.scss'
const AssetsBox = (props) => {
    // console.log(props);
    return (
        <Grid item xs={12} sm={6} md={2.5} >
            {props.children}
        </Grid>

    )
}

export default AssetsBox;