import StudentProfile from "../../_Components/Common/ReportCard/StudentPofile";
import GuidelinesForParents from "../../_Components/Common/ReportCard/GuidelinesForParents";
import MainCard from "../../_Components/Common/Cards/MainCard";
import GradingScales from "../../_Components/Common/ReportCard/GradingScale";
import CoScholasticProfile from "../../_Components/Common/ReportCard/CoScholasticProfile";
import ScholasticAssessmentTerm1 from "../../_Components/Common/ReportCard/ScholasticAssessmentTerm1";
import ScholasticAssessmentTerm2 from "../../_Components/Common/ReportCard/ScholasticAssessmentTerm2";
import FinalResult from "../../_Components/Common/ReportCard/FinalReport";

const ReportCard = () => {
  return (
    <MainCard cardTitle="Report Card" customClass="">
      <StudentProfile />
      <GuidelinesForParents/>
      <GradingScales/>
      <CoScholasticProfile/>
      <ScholasticAssessmentTerm1/>
      <ScholasticAssessmentTerm2/>
      <FinalResult/>
    </MainCard>

  );
};

export default ReportCard;
