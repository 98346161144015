import React, { useState, useRef, useEffect } from "react";
import { SketchPicker } from "react-color";

const ColorPicker = (props) => {
  const [color, setColor] = useState("#000000");
  const [open, setOpen] = useState(false);

  const colorPickerRef = useRef();

  const handleChange = () => {
    setOpen(!open);
  };

  const handleColorChange = (newColor) => {
    props.func(newColor.hex);
    setColor(newColor.hex);
  };

  useEffect(() => {
    props.cColor(setColor);

    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, []);

  return (
    <div ref={colorPickerRef}>
      <div
        onClick={handleChange}
        style={{
          height: "auto",
          minHeight: "1.4375em",
          padding: "8.5px 14px",
          border: "1px solid #adb4bd",
          borderRadius: "4px",
          cursor: "pointer",
          background: color,
          textAlign: "center"
        }}
      >
        <span style={{ color: color, filter: "invert(100%)", fontWeight: "600" }}>{color}</span>
      </div>
      {open && (
        <div style={{ position: "absolute", zIndex: "9", bottom: "10px" }}>
          <SketchPicker color={color} onChange={handleColorChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;