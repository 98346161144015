import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";



const PrimaryCard = ({ curLanguage, children, titleClass, titleVariant }) => {
  return (
    <Card className="card-primary" sx={{ backgroundColor: "darkblue.color" }}>
      <Typography
        className={titleClass}
        variant={titleVariant}
        sx={{
          color: "#fff",
          backgroundColor: "secondaryColor.main",
          padding: "8px 20px 8px",
        }}
      >
       {curLanguage}
      </Typography>
      <Box className="chart-container">{children}</Box>
    </Card>
  );
};

export default PrimaryCard;
