  // GermanLanguage.js
  const GermanLanguage = {
 
        // login page 
        "login": "Anmeldung",
        "username": "Nutzername",
        "password": "Passwort",
        "forgetpassword": "Passwort vergessen",
        "learnMore": "mehr erfahren",
        "Downloadbutton": "Herunterladen",

        // Dashboard page 
        "mycourses": "Meine Kurse",
        "myclasses": "My Classes",
        "myattendance": "My Attendance",
        "recentactivities": "Kürzlich aufgerufene Aktivitäten",
        "recentcourses": " Kürzlich aufgerufene Kurse",
        "recentassignments": "Onlangs geopende opdrachten",
        "norecentassignments": "Geen recent geopende opdrachten",
        "dashboard": "Armaturenbrett",
        "bookmarks": "Lesezeichen",
        "settings": "Einstellungen",

        "norecentactivities": "Keine kürzlich aufgerufenen Aktivitäten",

        "norecentcourses": "Keine kürzlich aufgerufenen Kurse",
        "class": "klas",
        // calendar
        "mycalendar": "Mein Kalender",
        "newevent": "Neues Event",
        "eventtitle": "Veranstaltungstitel",
        "eventdate": "Datum",
        "eventtype": "Art der Veranstaltung",
        "eventdescription": "Beschreibung",
        "eventlocation": "Standort",
        "eventduration": "Dauer",
        // ===========================
        "save": "Speichern",
        "delete": "Löschen",
        "edit": "Bearbeiten",
        "close": "Schließen",
        "cancel": "Stornieren",
        "showmore": "Zeig mehr",
        // ===========================
        "quiz": "Bewertung",

        //Footer Folder
        "readMore": "Weiterlesen",
        "visitUs": "Besuchen Sie uns",
        "aboutUs": "Über uns",
        "qualityPolicy": "Qualitätsrichtlinie",
        "info": "Die Info",
        "contactUs": "Kontaktiere uns",

        //popup  create events  
        "createNewEvent": "Nieuw Evenement Maken",
        "createEvent": "Evenement Maken",
        "eventDate": "Datum",
        "eventDateUntil": "Datum bis",
        "durationInMinutes": "Dauer in Minuten",
        "description": "Beschreibung",
        "minutes": "Protokoll",
        "repeatThisEvent": "Wiederholen Sie dieses Ereignis",
        "repeatWeeklyEvent": "Wiederholen Sie dieses Ereignis",

        //popup get event details 
        "date": "Datum",
        "userName": "Nutzername",
        "deleteAllEvent": "Alle Ereignisse löschen",
        "areYouSure": "Bist du dir sicher.",

        // Assessment Attempt
        "previous": "Vorherige",
        "next": "Nächste",
        "QuizNavigation": "Quiz-Navigation",

        // create Calendar Event
        "Typeofevent": "Art der Veranstaltung",
        "course": "Kurs",
        "MySelf": "Ich selbst",

        //user Popup
        "profile": "Profil",
        "grades": "Noten",
        "calendar": "Kalender",
        "messages": "Mitteilungen",
        "preferences": "Präferenzen",
        "Logout": "Ausloggen",
        "YouAreloggedInAs": "Du bist eingeloggt als ",

        // user Profile
        "Userdetails": "Nutzerdetails",
        "editprofile": "Profil bearbeiten",
        "Emailaddress": "E-Mail-Addresse",
        "Citytown": "Stadt/Ort",
        "Loginactivity": "Anmeldeaktivität",
        "Firstaccesstosite": "Erster Zugriff auf die Website",
        "Lastaccesstosite": "Letzter Zugriff auf die Website",
        "Coursedetails": "Kursdetails",

        // report
        "report": "Bericht",
        "finished": "Eingereicht",
        "name": "Naam",
        "notattempted": "Nicht eingereicht",
        "review": "Rezension",
        "emailAddress": "E-Mail-Addresse",
        "status": "Status",
        "started": "Begann am",
        "completed": "Vervollständigt am",
        "timetaken": "Zeit genommen",
        "grade": "Grad %",
        "marks": "Markierungen/",
        "q1": "Q. 1",
        "q2": "Q. 2",
        "q3": "Q. 3",
        "q4": "Q. 4",
        "q5": "Q. 5",
        "q6": "Q. 6",
        "q7": "Q. 7",
        "q8": "Q. 8",

        "q9": "Q. 9",
        "q10": "Q. 10",
        "q11": "Q. 11",

        "q12": "Q. 12",
        "q13": "Q. 13",

        "q14": "Q. 14",
        "q15": "Q. 15",
        //question 
        "question": "Frage",
        "answered": "Antwortete",
        "correct": "Richtig",
        "wrong": "Falsch",
        "notatt": "Nicht versucht",
        "quizNavigation": "Quiz-Navigation",

        // Attendance
        "Totaldays": "Gesamte Tage",
        "Totalpresent": "Völlig abwesend",
        "Totalabsent": "Völlig anwesend",
        "Totalleaves": "Verzichten auf",
        "markattendance": "Markeer aanwezigheid",

        //Admission Form Page              
        "fname": "Voornaam",
        "lname": "Achternaam",
        "email": "e-mailen",
        "image": "Afbeelding Uploaden",
        "admissionform": "Toelatingsformulier",
        "admissionno": "toelatingsnummer",
        "studentdetail": "Gegevens van de leerling",
        "dob": "Geboortedatum",
        "gender": "Geslacht",
        "female": "Vrouwelijk",
        "male": "Mannelijk",
        "other": "Ander",
        "category": "Categorie",
        "general": "Algemeen",
        "obc": "OBC",
        "sc": "SC",
        "st": "ST",
        "religion": "Religie",
        "hindu": "Hindoe",
        "muslim": "Moslim",
        "christian": "christen",
        "aadhar": "Aadhar-kaart Uploaden",
        "photoupload": "Foto Uploaden",
        "document": "Document Uploaden",
        "address": "Adres",
        "permanent": "Vast Adres",
        "current": "Huidige adres",
        "addressline1": "Adres regel 1",
        "addressline2": "Adres regel 2",
        "birthCertificate": "Geboortecertificaat Uploaden",
        "city": "Stad",
        "state": "Staat",
        "pincode": "Pincode",
        "country": "Land",
        "parentdetails": "Gegevens van de ouder",
        "mothername": "moeder naam",
        "motheroccupation": "Moeder Beroep",
        "motherphone": "Moeders telefoonnummer",
        "fathername": "Vader naam",
        "fatheroccupation": "vader beroep",
        "fatherphone": "Telefoonnummer vader",
        "selectBoth": "Selecteer of beide adressen hetzelfde zijn",
        "documents": "documenten",
        "submit": "indienen",
        "blood": "bloedgroep",
        "blood1": "O+",
        "blood2": "O-",
        "blood3": "A+",
        "blood4": "A-",
        "blood5": "B+",
        "blood6": "B-",
        "blood7": "AB+",
        "blood8": "AB-",
        "nationality": "nationaliteit",
        "ews": "Economisch zwakkere sectie (EWS)?",
        "sibling": "Broer of zus op dezelfde school?",
        "transport": "Vervoer nodig?",
        "guardian": "Naam voogd",
        "relation": "Relatie met kind",
        "schollname": "Achternaam school",
        "schoolAddress": "Laatste schooladres",
        "physical": "Fysiek gehandicapt?",
        "mentally": "Mentaal uitgedaagd?",
        "illness": "Een ernstige ziekte?",
        "descriptionIllness": "Beschrijving van ziekte",
        "ewscertificate": "EWS-certificaat uploaden",
        "certificate": "certificaat",
        "section": "sectie",
        "feereceipt": "vrijbewijs",
        "imgsize": "Img-grootte moet minimaal 120 * 120 px zijn",
        "pancard": "upload pankaart",
        "salutation": "begroeting",
        "fullname": "voor-en achternaam",
        "highqualification": "hoge kwalificatie",
        "occupation": "bezigheid",
        "annualIncome": "jaarlijks inkomen",
        "officeAddress": "Kantoor adres",
        "phoneNumber": "telefoonnummer",
        "mobile": "mobiel",
        "contactNo": "contactnr",
        "Parentsdetail": "Gegevens ouders",
        "registrationNo": "Registratienummer",

        //Notes Page
        "mynotes": "Mijn notities",
        "title": "Titel",
        "createdOn": "Gemaakt op",
        "modifiedOn": "Gewijzigd op",
        "action": "actie",

        //List items
        "mystuff": "mijn spullen",
        "classes": "klassen",
        "attendance": "Aanwezigheid",
        "notes": "notities",
        "fees": "vergoedingen",
        "admission": "erkenning",
        "courses": "Kurse",

        //Fee Collection        
        "feecollection": "Incassokosten",
        "studentname": "Studenten Naam",
        "createcertificate": "Certificaat Maken",
        "viewcertificate": "Bekijk certificaat",
        "search": "Zoekopdracht",
        "notify": "Melden",
        "receivefee": "Vergoeding Ontvangen",


        //Create Certificate        
        "create": "Creëren",
        "enter": "Binnenkomen",

        //Exam Planner
        "examPlanner": "Planner",
        "timeTable": "rooster",        
        "annualPlanner": "Annual Planner",
        "usermgmt": "User Management",
 
  };
  
  export default GermanLanguage;
  