import { Box, Grid, Typography } from "@mui/material";
import "./FeatureCard.scss"

const FeatureCard = ({ cardHeading, children, cardIcons, altTxt, className, lgSize, mdSize, sxSize}) => {
  return (
    <Grid item lg={lgSize} md={mdSize} sm={sxSize} className={className}>
      <Box className="feature-card">
        <img
          src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/FeatureIcons/${cardIcons}`}
          alt={altTxt}
        />
        <Typography variant="h6" className="feature-heading">
          {cardHeading}
        </Typography>
        <Box className="feature-content">{children}</Box>
      </Box>
    </Grid>
  );
};

export default FeatureCard;
