import React from "react";
import { Button } from "@mui/material";

const PrimaryBgBtn = ({ children, ...props }) => {
  return (
    <Button variant="contained" color="primary" className="btn" {...props}>
      {children}
    </Button>
  );
};

const PrimaryOutlineBtn = ({ children, ...props }) => {
  return (
    <Button variant="outlined" color="primary" className="btn" {...props}>
      {children}
    </Button>
  );
};

const SecondaryBgBtn = ({ children, ...props }) => {
  return (
    <Button variant="contained" color="secondary" className="btn" {...props}>
      {children}
    </Button>
  );
};

const SecondaryOutlineBtn = ({ children, ...props }) => {
  return (
    <Button variant="outlined" color="secondary" className="btn" {...props}>
      {children}
    </Button>
  );
};

export { PrimaryBgBtn, PrimaryOutlineBtn, SecondaryBgBtn, SecondaryOutlineBtn };
