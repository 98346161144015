import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./page404.scss";
import { Box, Button, Grid, Typography } from "@mui/material";

function ErrorBoundryUI() {
  useEffect(() => {});
  const navigate = useNavigate();

  const handleDashboard = () => {
    navigate("/dashboard");
    window.location.reload(true);
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    return navigate("/");
  };
  return (
    <Box className="page404-main" sx={{ backgroundColor: "commonBg.bgColor" }}>
      <Box className="page404Content">
        <img
          src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/error.gif`}
          alt="error.gif"
        />
        <Typography variant="h6" mb={2}>
          Something went wrong. Please try again.
        </Typography>
        <Grid container spacing={1}>
          <Grid item md="6" xs="12">
            <Button
              variant="contained"
              onClick={handleDashboard}
              className="btn"
            >
              Dashboard
            </Button>
          </Grid>
          <Grid item md="6" xs="12">
            <Button variant="contained" onClick={handleLogout} className="btn">
              Logout
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ErrorBoundryUI;
