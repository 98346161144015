import { Box, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";

const PrincipalCards = ({ links, customclass, icons, text }) => {
  return (
    <Grid item md={3}>
      <Box className={customclass ? `${customclass} mainCard` : "mainCard"}>
        <NavLink to={links} className="adminList">
          <Box className="listImg">
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/${icons}`}
              alt={icons}
              height={75}
            />
          </Box>
          <Box className="listHead">{text}</Box>
        </NavLink>
      </Box>
    </Grid>
  );
};

export default PrincipalCards;
