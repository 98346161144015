import { useState } from "react";
import MainCard from "../../../_Components/Common/Cards/MainCard";
import PrincipalFilter from "../../../_Components/Common/Filter/PrincipalFilter";
import { Card, Container } from "@mui/material";

const UserCertificate = () => {
  const [filterClass, setFilterClass] = useState("");
  const [filterSection, setFilterSection] = useState("");
  const [filterSubject, setFilterSubject] = useState("");
  const [filterDepartment, setFilterDepartment] = useState("");

  // Get student info for filtering purposes
  const handleFilter = (stdclass, stdsection, stdsubject, department) => {
    setFilterClass(stdclass);
    setFilterSection(stdsection);
    setFilterSubject(stdsubject);
    setFilterDepartment(department);
    console.log(stdclass, stdsection, stdsubject, department, "filter value");
  };
  return (
    <>
      <MainCard cardTitle="User Certificate">
        <PrincipalFilter
          forDepartment
          forClass
          forSection
          onselectionchangeData={handleFilter}
        />
      </MainCard>
      {filterDepartment && (
        <Container maxWidth="xl">
          <Card
            sx={{
              backgroundColor: "cardColor.main",
              color: "cardColor.contrast",
              position: "relative",
              marginTop: "16px",
              padding: "10px 0 ",
            }}
            elevation={5}
            className="customCard"
          >
            {filterDepartment === "Pedagogy" && <p>pedagogy</p>}
            {filterDepartment === "student" && <p>student</p>}
          </Card>
        </Container>
      )}
    </>
  );
};

export default UserCertificate;
