import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./QuizStudentList.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { languages } from "../../App";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiOutlineMinusCircle } from "react-icons/ai";

function QuizStudentList() {
  console.log("qsl");
  let curLanguage = useContext(languages);
  const navigation = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [studentState, setStudentState] = useState([]);
  const [stFullData, setStFullData] = useState([]);
  const [rows, setRows] = useState([]);
  const [selClass, setSelClass] = useState("all");
  const [isExpanded, setExpanded] = useState(false);

  const teacherClassTemp = location.state.list.map(
    (student) => student.class + student.section
  );
  const teacherClass = [...new Set(teacherClassTemp)];

  const menuItems = teacherClass.map((menu, indx) => (
    <MenuItem key={indx} value={menu}>
      {menu}
    </MenuItem>
  ));

  const reviewAttemptHandler = (attID, fullname) => {
    console.log("handler")
    const reviewAttemptURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_attempt_review_info&moodlewsrestformat=json&attemptid=${attID}`;
    console.log(reviewAttemptURL, "2525225");

    axios.get(reviewAttemptURL).then((response) => {
      navigation("quizattemptreview", {
        state: { attemptDetail: response.data, fullname },
      });
    });
  };

  const getMaxQuestions = (data) => {
    let lengthArr = data.map((data) => {
      return data.length;
    });

    return Math.max(...lengthArr);
  };

  let maxQuest;

  if (studentState.length > 0) {
    console.log(studentState, "t1");
    if (studentState[4].ques_status) {
      maxQuest = getMaxQuestions(studentState[4].ques_status);
    } else {
      maxQuest = 0;
    }
  }

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "fullname",
      headerName: "Full Name",
      width: 180,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      editable: false,
    },

    {
      field: "grade",
      headerName: "Class",
      width: 100,
      editable: false,
    },

    {
      field: "state",
      headerName: "State",
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              color: cellValues.row.state === "finished" ? "Green" : "red",
            }}
          >
            {cellValues.row.state}
          </Box>
        );
      },
    },

    {
      field: "reviewattempt",
      headerName: "Review Attempt",
      width: 180,
      renderCell: (cellValues) => {
        return (
          cellValues.row.state === "finished" && (
            <Button
              id={cellValues.row.tempID}
              variant="contained"
              sx={{ backgroundColor: "iconBg.main", color: "iconColor.color" }}
              className="reviewButton"
            >
              <Typography
                sx={{ color: "iconColor.color" }}
                onClick={() =>
                  reviewAttemptHandler(
                    cellValues.row.attID,
                    cellValues.row.fullname
                  )
                }
              >
                View
              </Typography>
            </Button>
          )
        );
      },
    },

    {
      field: "timeTaken",
      headerName: "Time Taken",
      width: 150,
      editable: false,
    },

    {
      field: "tmarks",
      headerName: "Grade",
      width: 150,
      editable: false,
    },
  ];

  if (isExpanded) {
    for (var i = 0; i < maxQuest; i++) {
      let cvalue = `q${i + 1}`;
      columns.push({
        field: `q${i + 1}`,
        headerName: `Q${i + 1}.`,
        width: 80,
        editable: false,
        renderCell: (cellValues) => {
          console.log(cellValues.row.state, "check");
          return cellValues.row.state === "finished" ? (
            cellValues.row[cvalue] === 1 ? (
              <CheckCircleOutlineIcon sx={{ color: "#137400" }} />
            ) : (
              <HighlightOffIcon sx={{ color: "red" }} />
            )
          ) : (
            "-"
          );
        },
      });
    }
  }

  const studentStateURL = `${process.env.REACT_APP_wsPrefix}/customwebservice/quiz/quizattempt.php?wsfunction=getquiz_attemptdata_all&wstoken=${token}`;

  useEffect(() => {
    axios.post(studentStateURL, JSON.stringify(parameters)).then((response) => {
      console.log("++++1111", response.data);
      console.log(maxQuest);
      setStudentState(response.data);

      let timeTaken = response.data[2];
      let scorePerQuest = response.data[4].ques_status;
      let gradePerc = response.data[3];

      let rowsTemp = location.state.list
        .map((data, indx) => {
          return {
            attID: response.data[0][indx],
            id: data.id,
            fullname: data.fullname,
            email: data.email,
            state: response.data[1][indx],
            grade: data.class + data.section,
            class: data.class,
            section: data.section,
            timeTaken:
              response.data[1][indx] !== "inprogress" ? timeTaken[indx] : "-",
            tmarks:
              response.data[1][indx] !== "finished"
                ? "-"
                : `${Math.round(parseInt(gradePerc[indx]))}%`,
          };
        })
        .filter((data) => {
          return data.attID !== undefined;
        });

      console.log(rowsTemp, "ddiis");

      console.log(response.data[4].ques_status, "ssss");
      let lengthArr = response.data[4].ques_status.map((data) => {
        return data.length;
      });
      console.log(lengthArr, "ssss");
      let maxQuestrows = Math.max(...lengthArr);

      console.log(rowsTemp, "22552");

      for (var u = 0; u < rowsTemp.length; u++) {
        for (var q = 0; q < maxQuestrows; q++) {
          rowsTemp[u][`q${q + 1}`] =
            scorePerQuest[u][q] === undefined ? "-" : scorePerQuest[u][q];
        }
      }

      console.log(rowsTemp, "ssss");

      setRows(rowsTemp);
      setStFullData(rowsTemp);
    });
  }, []);

  const classChangeHandler = (e) => {
    setSelClass(e.target.value);
    let selectedClass = e.target.value.split("");

    let rowsTemp;

    if (selectedClass[0] !== "a") {
      let selectedClassStudents = stFullData.filter((student) => {
        return (
          student.class === selectedClass[0] &&
          student.section === selectedClass[1]
        );
      });

      rowsTemp = selectedClassStudents;
    } else {
      rowsTemp = location.state.list.map((data, indx) => {
        return {
          attID: studentState.length > 0 ? studentState[0][indx] : "",
          id: data.id,
          fullname: data.fullname,
          email: data.email,
          grade: data.class + data.section,
          state: studentState.length > 0 ? studentState[1][indx] : "Loading...",
        };
      });
    }

    setRows(rowsTemp);
  };

  const parametersID = location.state.list.map((data) => {
    return data.id;
  });

  const parameters = {
    quiz_id: location.state.quizID,
    user_id: parametersID,
  };

  const expandHandler = () => {
    setExpanded((prevData) => !prevData);
  };

  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          p: 0,
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main", marginBottom: "30px" }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item md={9} xs={9}>
              <Box className="titleHead">
                <BackButton />
                <b>{location.state.title}</b>
              </Box>
            </Grid>
          </Grid>
        </Typography>
        {console.log(rows, "ssss")}
        {rows.length > 0 ? (
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item md={6} xs={12}>
              <Box>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id="accessmentSelectClass">
                    Select Section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Class"
                    onChange={classChangeHandler}
                    value={selClass}
                  >
                    <MenuItem value="all">All Sections</MenuItem>
                    {menuItems}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6} xs={12} className="showMore">
              <Box className="showMore-box" onClick={expandHandler}>
                {isExpanded ? (
                  <Box display={"flex"} alignItems={"center"}>
                    <AiOutlineMinusCircle size={25} />
                    <Typography>Show Less</Typography>
                  </Box>
                ) : (
                  <Box display={"flex"} alignItems={"center"}>
                    <IoIosAddCircleOutline size={28} />
                    <Typography>Show More</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ padding: "10px" }}>
            There is no attempts for this Assessment
          </Box>
        )}

        {rows.length > 0 ? (
          <Box sx={{ padding: "10px" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              disableRowSelectionOnClick
            />
          </Box>
        ) : (
          ""
        )}
      </Card>
    </Container>
  );
}

export default QuizStudentList;
