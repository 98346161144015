import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { useEffect, useState } from "react";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";
import MainCard from "../../_Components/Common/Cards/MainCard";
import ExamSheetList from "./ExamSheetList";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

const ExamSheetCreator = () => {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token");
  const [stdClassInfo, setStdClassInfo] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examname, setExamname] = useState("");
  const [subjectsVal, setSubjectsVal] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const [formValues, setFormValues] = useState([
    {
      stdClass: "",
      stdsection: "",
      subject: "",
      date: null,
      duration: "",
    },
  ]);

  const handleInputData = (index, event) => {
    const { name, value } = event.target;
    const newFormValues = [...formValues];
    newFormValues[index][name] = value;
    setFormValues(newFormValues);
    if (name === "stdClass" || name === "stdsection") {
      const selectedClass =
        name === "stdClass" ? value : newFormValues[index].stdClass;
      const selectedSection =
        name === "stdsection" ? value : newFormValues[index].stdsection;

      if (selectedClass && selectedSection) {
        const selectedClassInfo = stdClassInfo.find(
          (item) =>
            item.class === parseInt(selectedClass) &&
            item.section === selectedSection
        );

        if (selectedClassInfo) {
          fetchSubjects(selectedClassInfo.group_name, index);
        }
      }
    }
  };

  const handleDateChange = (index, date) => {
    const newFormValues = [...formValues];
    newFormValues[index].date = date;
    setFormValues(newFormValues);
    console.log(date, "dateeee");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        const data = response.data.class_section;
        setStdClassInfo(data);
        const uniqueClasses = [...new Set(data.map((item) => item.class))];
        setClasses(uniqueClasses);
        const uniqueSections = [...new Set(data.map((item) => item.section))];
        setSections(uniqueSections);
      } catch (error) {
        console.error("Error fetching class and section data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchSubjects = async (groupName, index) => {
    try {
      const subjectBaseURL = `${process.env.REACT_APP_nodewsPrefix}/getClassSubjects`;
      const response = await axios.post(
        subjectBaseURL,
        {
          class_name: formValues[index].stdClass + formValues[index].stdsection,
          schoolcode: userinfo[0]?.institution,
          group_name: groupName,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      const newFormValues = [...formValues];
      newFormValues[index].subjects = response.data.subject_data;
      setSubjectsVal(newFormValues[index].subjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const aggregatedData = formValues.reduce(
      (acc, formValue) => {
        const classIndex = acc.stdClass.indexOf(parseInt(formValue.stdClass));
        if (classIndex === -1) {
          acc.stdClass.push(parseInt(formValue.stdClass));
          acc.section.push([formValue.stdsection]);
          acc.courseid.push([formValue.subject]);
          acc.date.push(formValue.date.$D);
          acc.hour.push(formValue.date.$H);
          acc.minute.push(formValue.date.$m);
          acc.month.push(formValue.date.$M + 1);
        } else {
          acc.section[classIndex].push(formValue.stdsection);
          acc.courseid[classIndex].push(formValue.subject);
          acc.date[classIndex].push(formValue.date.$D);
          acc.hour[classIndex].push(formValue.date.$H);
          acc.minute[classIndex].push(formValue.date.$m);
          acc.month[classIndex].push(formValue.date.$M + 1);
        }
        return acc;
      },
      {
        title: examname,
        description: "",
        repeats: 0,
        stdClass: [],
        section: [],
        courseid: [],
        eventtype: "course",
        year: String(formValues[0].date.$y),
        month: [],
        date: [],
        hour: [],
        minute: [],
        token: token,
        instituteid: userinfo[0]?.institution,
      }
    );

    const createEventURL = `${process.env.REACT_APP_nodewsPrefix}/createExamEvent`;
    try {
      await axios.post(createEventURL, aggregatedData, {
        headers: {
          Authorization: `Bearer ${nToken}`,
        },
      });
      alert("Exam events created successfully");
    } catch (error) {
      console.error("Error while creating events:", error);
    }
  };

  const addRow = () => {
    setFormValues([
      ...formValues,
      {
        stdClass: "",
        stdsection: "",
        subject: "",
        date: null,
        duration: "",
      },
    ]);
  };

  const removeRow = (index) => {
    const newFormValues = formValues.filter((_, i) => i !== index);
    setFormValues(newFormValues);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <TextField
        id="examname"
        label="Exam Name"
        variant="outlined"
        name="examname"
        value={examname}
        onChange={(e) => setExamname(e.target.value)}
        fullWidth
        required
      />
      {formValues.map((formValue, index) => (
        <Grid container spacing={1} mt={1} key={index}>
          <Grid item md={2}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="stdClass-label">Class</InputLabel>
              <Select
                labelId="stdClass-label"
                id="stdClass"
                name="stdClass"
                value={formValue.stdClass}
                onChange={(event) => handleInputData(index, event)}
                label="Class"
              >
                {classes.map((cls) => (
                  <MenuItem key={cls} value={cls}>
                    {cls}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="stdsection-label">Section</InputLabel>
              <Select
                labelId="stdsection-label"
                id="stdsection"
                name="stdsection"
                value={formValue.stdsection}
                onChange={(event) => handleInputData(index, event)}
                label="Section"
              >
                {sections.map((section) => (
                  <MenuItem key={section} value={section}>
                    {section}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="subject-label">Subject</InputLabel>
              <Select
                labelId="subject-label"
                id="subject"
                name="subject"
                value={formValue.subject}
                onChange={(event) => handleInputData(index, event)}
                label="Subject"
              >
                {formValue.subjects?.map((option) => (
                  <MenuItem key={option.courseid} value={option.courseid}>
                    {option.course_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                fullWidth
                label="Date"
                value={formValue.date}
                onChange={(date) => handleDateChange(index, date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                format="DD-MM-YYYY"
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={2}>
            <TextField
              id="duration"
              label="Duration"
              variant="outlined"
              name="duration"
              value={formValue.duration}
              onChange={(event) => handleInputData(index, event)}
              fullWidth
            />
          </Grid>
          {formValues.length > 1 && (
            <Grid item md={1}>
              <IconButton aria-label="Delete row" sx={{ padding: "1px" }}>
                <AiOutlineMinusCircle
                  onClick={() => removeRow(index)}
                  fontSize={26}
                />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      <IconButton
        aria-label="Add row for other class or subject"
      >
        <AiOutlinePlusCircle onClick={addRow} fontSize={26} color="primary"/>
      </IconButton>
      <Box textAlign={"center"} mt={2}>
        <PrimaryBgBtn type="submit">Submit</PrimaryBgBtn>
      </Box>
    </form>
  );
};

export default ExamSheetCreator;
