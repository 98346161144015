import "./Doubt.scss";
import { Box, Container } from "@mui/material";
import user from "../../images/rubber.jpg";
import { BsChatLeftText } from "react-icons/bs";
import { ImEye } from "react-icons/im";
import { FiUsers } from "react-icons/fi";

const doubtUser = [
  {
    name: "Bhawna",
    date: "2 days",
    data: "How to discover seamless digital transformation which makes it easier to engage with students in new and effective ways?",
  },
  {
    name: "Nityanand",
    date: "3 days",
    data: "How to discover seamless digital transformation which makes it easier to engage with students in new and effective ways?",
  },
  {
    name: "Vikas",
    date: "1 week",
    data: "How to discover seamless digital transformation which makes it easier to engage with students in new and effective ways?",
  },
];

const Doubt = () => {
  return (
    <Container maxWidth="xl">
      <Box className="doubtWrapper">
        {doubtUser.map((info, infoIndex) => (
          <Box
            className="doubtSec"
            key={infoIndex}
            sx={{
              backgroundColor: "template.bgColor",
              color: "template.color",
            }}
          >
            <Box className="userSec">
              <Box className="userImg">
                <img src={user} alt="userImg" />
              </Box>
              <Box className="userInfo">
                <b>{info.name}</b>
                <small>asked {info.date} ago</small>
              </Box>
            </Box>
            <Box className="quesArea" sx={{}}>
              {info.data}
            </Box>
            <Box className="footerArea">
              <Box className="footerAction">
                <BsChatLeftText /> Comments
              </Box>
              <Box className="footerAction">
                <FiUsers /> Answered
              </Box>
              <Box className="footerAction">
                <ImEye /> Viewed
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default Doubt;
