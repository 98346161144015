import React, { Component } from "react";
import Slider from "react-slick";
import "./slick.min.css";
import "./slick-theme.min.css";
import "./customSlider.scss";
import "animate.css";
import SliderContent from "./SliderContent";

export default function SimpleSlider() {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
  };
  return (
    <Slider {...settings}>
      <SliderContent
        sliderImg={"slider1.jpg"}
        content={"Learning Made Simple, Results Made Significant."}
        subText={"Revolutionize Learning: Explore Our LMS Today!!"}
        altTxt={
          "A boy smiling at the camera while sitting in front of a computer in a classroom."
        }
        customClass="content text1"
      />
      <SliderContent
        sliderImg={"slider2.jpg"}
        content={
          "Teach, Track, Transform: Empowering Educators, Enhancing Learning"
        }
        altTxt={
          "A man explaining concepts on a whiteboard in a classroom setting."
        }
        customClass="content text2"
      />
      <SliderContent
        sliderImg={"slider3.jpg"}
        content={
          "Explore, Learn, Succeed! Transforming education through simplified student experiences."
        }
        altTxt={
          "A girl smiling and using a tablet in a library filled with books. "
        }
        customClass="content text3"
      />
      <SliderContent
        sliderImg={"slider4.jpg"}
        content={"Learn, Manage, Simplify"}
        altTxt={
          "Three girls in school uniforms smiling and looking at a laptop outdoors"
        }
        customClass="content text4"
      />
    </Slider>
  );
}
