import {
  Box,
  Typography,
  Container,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./stdTimeTbl.scss";
import BackButton from "../Common/BackBtn/BackBtn";
import { Scrollbar } from "react-scrollbars-custom";
import axios from "axios";
import "./TimeTable.scss";
import MainBackBtn from "../Common/BackBtn/MainBackBtn";

const StudentTimeTable = () => {
  const currentDate = new Date();
  const financialYear = localStorage.getItem("financialYear");
  const dayOfWeek = currentDate.getDay();

  const dayOf = dayOfWeek - 1;

  const stdUser = JSON.parse(localStorage.getItem("classInfo"));
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [timeTblData, setTimeTblData] = useState("");
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  

  useEffect(() => {
    const fetchTimeTbl = async () => {
      try {
        const timeTblUrl = `${process.env.REACT_APP_nodewsPrefix}/viewAllTimeTable`;
        const response = await axios.post(timeTblUrl, {
          class: stdUser.class,
          section: stdUser.section,
          financial_year: financialYear,
          schoolcode: userInfo[0]?.institution
        });
        setTimeTblData(response.data.timetable_data);
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    fetchTimeTbl();
  }, []);

  const periods = Array.from(
    new Set(Object.keys(timeTblData).flatMap((period) => period))
  );

  const days = Array.from(
    new Set(
      Object.values(timeTblData).flatMap((day) =>
        Object.keys(day).map((d) => d)
      )
    )
  ).sort();

  return (
    <Container maxWidth="xl">
      <Box className="">
        <Card
          sx={{
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
          elevation={5}
          className="customCard "
        >
          <Typography
            className="title"
            variant="h5"
            px={2}
            py={1}
            sx={{ borderColor: "borderLine.main" }}
          >
            <Box className="titleHead">
              <MainBackBtn />
              <b>Time Table</b>
            </Box>
          </Typography>
          <Box className="customCardContent">
            {!timeTblData ? (
              "Timetable data is rendering"
            ) : (
              <TableContainer>
                <Scrollbar style={{ width: "100%", height: `calc(72vh - 20px)` }}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    className="timeTbl"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Day/Period</TableCell>
                        {periods.map((period) => (
                          <TableCell key={period}>
                            {period.slice(0, 6).toLocaleUpperCase()}{" "}
                            {period[period.length - 1]}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {days.map((day) => (
                        <TableRow key={day}>
                          <TableCell
                            className="daysName"
                            sx={{ border: "0", padding: "5px" }}
                          >
                            {day.toLocaleUpperCase()}
                          </TableCell>
                          {periods.map((period) => (
                            <TableCell
                              key={period}
                              sx={{ border: "0", padding: "5px" }}
                            >
                              {timeTblData[period][day] && (
                                <div className="teacherTimeTblInfo">
                                  <Card
                                    className={`${
                                      day?.toLowerCase() ===
                                      daysOfWeek[dayOfWeek - 1]?.toLowerCase()
                                        ? "yellowShade weekBox"
                                        : "periodSubj blueShade weekBox"
                                    }`}
                                  >
                                    {period === "period_0" ? (
                                      "Recess"
                                    ) : (
                                      <>
                                        <Typography
                                          fontSize={16}
                                          fontWeight={500}
                                          className="subjTitle"
                                        >
                                          {
                                            timeTblData[period][day]
                                              .subject_name
                                          }
                                        </Typography>

                                        <Typography
                                          variant="body"
                                          className="teacherTitle"
                                        >
                                          {
                                            timeTblData[period][day]
                                              .teacher_name
                                          }
                                        </Typography>
                                      </>
                                    )}
                                  </Card>
                                </div>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
            )}
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default StudentTimeTable;
