import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ListingCard from "../../_Components/Common/Cards/ListingCard/ListingCard";
import { Scrollbar } from "react-scrollbars-custom";
import "./Planner.scss";
import Loader from "../../_Components/Utils/loader/Loader";
import CardSecondary from "../../_Components/Common/Cards/CardSecondary";

const ExamSheetList = () => {
  const token = localStorage.getItem("token");
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  useEffect(() => {
    const getUpcomingData = () => {
      axios
        .get(
          `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_calendar_get_calendar_upcoming_view_info&moodlewsrestformat=json&categoryid=0`
        )
        .then((response) => {
          setUpcomingEvent(response.data.events);
          console.log(response.data.events, "calendar upcoming events");
        });
    };

    getUpcomingData();
  }, []);

  return (
    <CardSecondary curLanguage="Upcoming Exam">
      <Scrollbar style={{ width: "100%", height: `calc(60vh - 20px)` }}>
        {upcomingEvent ? (
          <>
            {upcomingEvent?.map((data, index) => (
              <Box key={index}>
                <ListingCard title={data.name}>
                  <Box display={"flex"} flexWrap={"wrap"}>
                    <Box mr={2} minWidth={"150px"}>
                      <b>Class: </b>
                      {data.class_name} {data.classsection}
                    </Box>
                    <Box mr={2} minWidth={"150px"}>
                      <b>Subject: </b>
                      {data.subject_name}
                    </Box>
                    <Box mr={2} minWidth={"150px"}>
                      <b>Duration: </b>
                      {data.description}
                    </Box>
                    <Box mr={2} minWidth={"150px"}>
                      <b>Date: </b>
                      {data.timestarted}
                    </Box>
                  </Box>
                </ListingCard>
              </Box>
            ))}
          </>
        ) : (
          <Typography textAlign={"center"}>No Event Found</Typography>
        )}
      </Scrollbar>
    </CardSecondary>
  );
};

export default ExamSheetList;
