import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Card, CardContent } from '@mui/material';

const FinalResult = () => {
  return (
    <Box  sx={{ padding: '2rem' }}>
      <Box  sx={{ marginBottom: '2rem' }}>
        <Box >
          <Box >
            <Box >
              <Box >
                <Typography variant="h3" >FINAL RESULT</Typography>
              </Box>
            </Box>
            <Box >
              <Box >
                <TableContainer component={Paper}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="h4" >SUBJECT</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h4" >FINAL SUBJECT GRADE</Typography>
                          <Typography variant="h6" >(Term I + Term II)</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {['English', 'Hindi', 'Mathematics', 'EVS', 'OVERALL GRADE'].map((subject) => (
                        <TableRow key={subject}>
                          <TableCell >{subject}</TableCell>
                          <TableCell align="center" className="text-center p-0">
                            <TextField
                              readOnly
                              value="A1"
                              variant="standard"
                              InputProps={{ disableUnderline: true, className: 'w-100 text-center' }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>

            <Box >
              <Box >
                <Box >
                  <Box ><Typography variant="h5">&#x2022; Total Attendance</Typography></Box>
                  <Box >
                    <TextField
                      readOnly
                      value="106/223"
                      variant="outlined"
                      InputProps={{ disableUnderline: true, className: 'w-100 border-dark InpOutline rounded text-start' }}
                    />
                  </Box>
                </Box>
                <Box >
                  <Box ><Typography variant="h5">&#x2022; Promoted to</Typography></Box>
                  <Box >
                    <TextField
                      readOnly
                      value="VI E"
                      variant="outlined"
                      InputProps={{ disableUnderline: true, className: 'w-100 border-dark InpOutline rounded text-start' }}
                    />
                  </Box>
                </Box>
                <Box >
                  <Box ><Typography variant="h5">&#x2022; Special Prize (s)</Typography></Box>
                  <Box >
                    <TextField
                      readOnly
                      value="Scholar Badge"
                      variant="outlined"
                      multiline
                      rows={2}
                      InputProps={{ disableUnderline: true, className: 'w-100 border border-dark InpOutline' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* <Box className="row mt-4">
              {['Class Teacher', 'Headmistress', 'Principal', 'Parent'].map((role, index) => (
                <Box className="col mt-2" key={index}>
                  <Card className="border border-dark">
                    <CardContent className="p-0" sx={{ textAlign: 'center' }}>
                      <Box
                        component="label"
                        htmlFor={`UploadSignature${index}`}
                        sx={{ width: '100%', height: '5rem', cursor: 'pointer', display: 'block', textAlign: 'center', border: '1px solid', marginBottom: '0.5rem' }}
                      >
                        <img src="images/signature.png" alt="" className="w-100 h-100" />
                      </Box>
                      <TextField
                        readOnly
                        value="A1"
                        variant="outlined"
                        type="file"
                        id={`UploadSignature${index}`}
                        sx={{ display: 'none' }}
                      />
                      <Typography variant="h6">{role}</Typography>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FinalResult;
