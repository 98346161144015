import {connect} from "react-redux";
import createEvent from "../_pages/calendar/createCalendarEvent/createCalendarEvent";

const mapStateToProps = state =>({
    userInfoData: state.items.data
})

const mapDispatchToProps= dispatch =>({
    
})


export default connect(mapStateToProps, mapDispatchToProps)(createEvent);