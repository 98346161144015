import { Box, IconButton, Menu, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { PrimaryBgBtn } from "./Buttons/CustomBtns";

const CustomMenu = ({
  imgName,
  altTxt,
  imgSize,
  tooltipTitle,
  iconClass,
  children,
  certCount,
  iconwdBtn,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderID = (id) => {
    return id > 9 ? "9+" : id;
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {iconwdBtn ? (
          <PrimaryBgBtn onClick={handleClick} startIcon={imgName}>{altTxt}</PrimaryBgBtn>
        ) : (
          <Tooltip title={tooltipTitle}>
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? iconClass : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              className={` ${iconClass}`}
            >
              {certCount ? (
                <Typography
                  className="count"
                  sx={{
                    position: "absolute",
                    top: "-3px",
                    right: "0px",
                    fontSize: "13px",
                    background: "red",
                    width: "20px",
                    height: "20px",
                    borderRadius: "100%",
                    color: "#fff",
                    fontWeight: "600",
                  }}
                >
                  {renderID(certCount)}
                </Typography>
              ) : (
                ""
              )}
              {imgName && (
                <img
                  src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/${imgName}`}
                  alt={altTxt}
                  width={imgSize}
                />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        Props={{
          elevation: 0,
          sx: {
            height: "500px",
            width: "200px",
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {children}
      </Menu>
    </>
  );
};

export default CustomMenu;
