import React from 'react';
import { Container, Box, Grid, Typography, TextField, TextareaAutosize, Paper, List, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ListItem } from '@mui/material';
import { styled } from '@mui/system';

const UploadImageLabel = styled('label')(({ theme }) => ({
  border: '1px solid black',
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  width: '10rem',
  height: '12rem',
  cursor: 'pointer',
  display: 'block',
  margin: '0 auto',
  '& img': {
    width: '100%',
    height: '100%',
  },
}));
const guidelines = [
    "* This progress profile is a gradual and comprehensive assessment of your child's personality and his/her physical & emotional development.",
    "* It is very important that your ward comes to school regularly as it helps us to assess the child more comprehensively.",
    "* The Scholastic Term Assessment will be reflected in the Progress Profile in the form of grades on an 8 point scale.",
    "* Criteria based scholastic skills as Scholastic Profile will be graded on a 6 Point Grading Scale.",
    "* Criteria based co-scholastic skills as Co-scholastic Profile will be graded on a 4 Point Grading Scale.",
    "* This report card is an important document and should be carefully perused & sent back to school duly signed, within two days of its receipt."
  ];
  const gradingScales = [
    {
      title: "GRADING SCALE (8 Point)",
      subtitle: "(For Scholastic Term Assessment)",
      rows: [
        { range: "91-100", grade: "A1" },
        { range: "81-90", grade: "A2" },
        { range: "71-80", grade: "B1" },
        { range: "61-70", grade: "B2" },
        { range: "51-60", grade: "C1" },
        { range: "41-50", grade: "C2" },
        { range: "33-40", grade: "D" },
        { range: "32 & below", grade: "E - Needs Improvement" }
      ]
    },
    {
      title: "GRADING SCALE (6 Point)",
      subtitle: "(For Scholastic Profile)",
      rows: [
        { grade: "O", remarks: "Outstanding" },
        { grade: "A", remarks: "Very Good" },
        { grade: "B", remarks: "Good" },
        { grade: "C", remarks: "Average" },
        { grade: "D", remarks: "Has Potential" },
        { grade: "E", remarks: "Needs to Improve" }
      ]
    },
    {
      title: "GRADING SCALE (4 Point)",
      subtitle: "(For Co-Scholastic Profile)",
      rows: [
        { grade: "A", remarks: "Outstanding" },
        { grade: "B", remarks: "Very Good" },
        { grade: "C", remarks: "Fair" },
        { grade: "D", remarks: "Scope for Improvement" }
      ]
    }
  ];
const StudentProfile = () => {
  return (
    <Container maxWidth="lg" sx={{ marginTop: 3 }}>
      <Box textAlign="center" mb={3}>
        <Typography variant="h4">STUDENT PROFILE</Typography>
      </Box>

      <Grid container justifyContent="center" mb={5}>
        <UploadImageLabel htmlFor="UploadImage">
          <img src="images/profilePic.png" alt="Profile" />
        </UploadImageLabel>
        {/* <input
          readOnly
          className="visually-hidden"
          type="file"
          id="UploadImage"
        /> */}
      </Grid>
      <Grid container justifyContent="center" mb={5}>
        <Box
          component={Grid}
          item
          xs={12}
          md={6}
          p={2}
          border="1px solid black"
          borderRadius={1}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6} >
              <Typography variant="subtitle1">NAME:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}  >
              <TextField fullWidth variant="outlined" value="Raman Sharma"  />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">ADMISSION NO.:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth variant="outlined" value="0001704" InputProps={{ readOnly: true }} />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">DATE OF BIRTH:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth variant="outlined" value="04-12-2019" InputProps={{ readOnly: true }} />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">MOTHER'S NAME:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth variant="outlined" value="Suman Singh Sharma" InputProps={{ readOnly: true }} />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">FATHER'S NAME:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth variant="outlined" value="Pankaj Sharma" InputProps={{ readOnly: true }} />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">ADDRESS:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextareaAutosize
                minRows={8}
                style={{ width: '100%', padding: '8.5px 14px', borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.23)', }}
                value="C-422, Josi Road, Karol Bagh, Delhi"
                readOnly
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">MOBILE NO./TEL NO.:</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth variant="outlined" value="98xxxx2501" InputProps={{ readOnly: true }} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      </Container>
  );
};
export default StudentProfile;
