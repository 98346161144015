import React from 'react';
import { Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const GradingScales = () => {
  const gradingData = [
    {
      title: 'GRADING SCALE (8 Point)',
      subtitle: '(For Scholastic Term Assessment)',
      tableHeaders: ['Marks Range', 'Grade'],
      rows: [
        { range: '91-100', grade: 'A1' },
        { range: '81-90', grade: 'A2' },
        { range: '71-80', grade: 'B1' },
        { range: '61-70', grade: 'B1' },
        { range: '51-60', grade: 'C1' },
        { range: '41-50', grade: 'C1' },
        { range: '33-40', grade: 'D' },
        { range: '32 & below', grade: 'E\nNeeds Improvement' },
      ],
    },
    {
      title: 'GRADING SCALE (6 Point)',
      subtitle: '(For Scholastic Profile)',
      tableHeaders: ['Grade', 'Remarks'],
      rows: [
        { range: 'O', grade: 'Outstanding' },
        { range: 'A', grade: 'Very Good' },
        { range: 'B', grade: 'Good' },
        { range: 'C', grade: 'Average' },
        { range: 'D', grade: 'Has Potential' },
        { range: 'E', grade: 'Needs to Improve' },
      ],
    },
    {
      title: 'GRADING SCALE (4 Point)',
      subtitle: '(For Co-Scholastic Profile)',
      tableHeaders: ['Grade', 'Remarks'],
      rows: [
        { range: 'A', grade: 'Outstanding' },
        { range: 'B', grade: 'Very Good' },
        { range: 'C', grade: 'Fair' },
        { range: 'D', grade: 'Scope for\nImprovement' },
      ],
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={4}>
        {gradingData.map((scale, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Box textAlign="center" mb={2}>
              <Typography variant="h6" component="div" gutterBottom>
                {scale.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {scale.subtitle}
              </Typography>
            </Box>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {scale.tableHeaders.map((header, idx) => (
                      <TableCell key={idx} align="center">
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scale.rows.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell align="center">{row.range}</TableCell>
                      <TableCell align="center">{row.grade}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GradingScales;
