import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { NavLink } from "react-router-dom";
import "./MoveCard.scss";
import { Grid } from "@mui/material";

const style = {
  cursor: "move",
};
export const ItemTypes = {
  CARD: 'card',
}

export const MoveCard = ({
  id,
  text,
  index,
  moveCard,
  links,
  icons,
  customclass,
  clickHand,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height.
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Grid item md={3}>
      <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
        {clickHand ? (
          <button
            onClick={clickHand}
            className={`${customclass} adminList`}
            style={{ width: "100%" }}
          >
            <div className="listImg">{icons}</div>
            <div className="listHead">{text}</div>
          </button>
        ) : (
          <NavLink to={links} className={`${customclass} adminList`}>
            <div className="listImg">{icons}</div>
            <div className="listHead">{text}</div>
          </NavLink>
        )}
      </div>
    </Grid>
  );
};
