import React, { useEffect, useState } from "react";
import { AccordionSummary, Box, Typography } from "@mui/material";
import ChapterCompletion from "./ChapterCompletion";
import axios from "axios";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";

const AccordionChildItem = ({
  valChild,
  courseID,
  classid,
  activityDetails,
  syllabusCheck,
  teacherCompletion,
}) => {
  const { permissions } = usePermissions();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const isCourseCompleted = syllabusCheck?.some(
    (data) => data.course_sectionid == valChild.id && data.status === 1
  );

  const isTeachercompleted = teacherCompletion?.some(
    (data) => data.course_sectionid == valChild.id && data.status === 1
  );
  const validChapter = valChild?.summary.includes("Chapter:");
 
  return (
    <AccordionSummary className="accordionSumm innerAccordionHEad">
      <Typography>
        <b>{valChild.name}</b>
      </Typography>
      {validChapter && (
        <>
          {isTeachercompleted && (
            <Box
              style={{
                position: "absolute",
                right: 30,
                top: 6,
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography>
                <small>Completed By Teacher</small>
              </Typography>
              <img
                src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/approved.png`}
                alt="checkBox"
                title="Chapter Completed By Teacher"
                width={35}
              />
            </Box>
          )}
          {permissions.markChapter?.write && permissions.markChapter?.read && (
            <ChapterCompletion
              courseID={courseID}
              valChild={valChild}
              classid={classid}
              isCourseCompleted={isCourseCompleted}
            />
          )}
        </>
      )}
    </AccordionSummary>
  );
};

export default AccordionChildItem;
