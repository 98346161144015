import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import Parser from "html-react-parser";
import { AiOutlineClockCircle, AiOutlineUser } from "react-icons/ai";

import "./getCalendarEvent.css";
import "./getCalendarEvent.scss";
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { languages } from "../../../App";
import IconWithText from "../../../_Components/Utils/IconWithText/IconWithText";
import DescriptionIcon from "@mui/icons-material/Description";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TextIcon from "../../../_Components/Utils/IconWithText/TextIcon";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsBook } from "react-icons/bs";

function GetCalendarEvent(props) {
  const [calendarEvent, setCalendarEvent] = useState({});
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [saveBtn, setSaveBtn] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const { id } = props;
  let curLanguage = useContext(languages);
  const [editedData, setEditedData] = useState({
    editEventDate: "",
    editEventName: calendarEvent?.event?.name,
    editDescription: calendarEvent?.event?.description,
  });

  const moduleNames = ["scorm", "quiz"];

  useEffect(() => {
    const baseURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${props.token}&wsfunction=core_calendar_get_calendar_event_by_id&moodlewsrestformat=json&eventid=${id}`;
    axios.get(baseURL).then((response) => {
      setCalendarEvent(response.data);
    });
  }, [id]);

  const parseHTML = (data) => {
    if (data == "") return "";
    let abc = <div dangerouslySetInnerHTML={{ __html: data }} />;
    return abc;
  };

  //Edited Data
  const handleContentChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [name]: value,
    }));
  };
  const handleDateChange = (newValue) => {
    setEditedData((prevState) => ({
      ...prevState,
      editEventDate: newValue,
    }));
  };

  const handleSaveEditData = async (
    eventID,
    createdUserId,
    repeatid,
    timestart
  ) => {
    try {
      const editedTimestamp = new Date(editedData.editEventDate);
      const timestampInSeconds = Math.floor(editedTimestamp.getTime() / 1000);
      console.log(timestampInSeconds, "timestampInSeconds");
      const editDataUrl = `${
        process.env.REACT_APP_wsPrefix
      }/webservice/rest/server.php?wstoken=${
        props.token
      }&wsfunction=core_calendar_submit_create_update_form&moodlewsrestformat=json&formdata=id=${eventID}%26userid=${createdUserId}%26_qf__core_calendar_local_event_forms_update=1%26name=${
        editedData.editEventName ?  editedData.editEventName : calendarEvent?.event?.name 
      }%26eventtype=user%26description=${
        editedData.editDescription ? editedData.editDescription : calendarEvent?.event?.description
      }%26timestart=${
        timestampInSeconds ? timestampInSeconds : timestart
      }%26repeatid=${repeatid ? repeatid : eventID}`;
      await axios.post(editDataUrl);
      props.isVisible(false);
    } catch (error) {
      console.error("Error editing event:", error);
    }
  };
  //Edited Data ends here

  const handleDelete = () => {
    props.setConfirm({
      isShow: true,
      eventCount: calendarEvent.event.eventcount,
    });
  };

  const handleSlideshow = () => {
    alert("work in progress");
  };
  const handleSaveBtn = () => {
    setIsEditing(false);
    setSaveBtn(true);
  };
  const multipleDelete = (e) => {
    let value = e.target.getAttribute("id");
    let deleteURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${props.token}&wsfunction=core_calendar_delete_calendar_events&moodlewsrestformat=json&events[0][eventid]=${id}&events[0][repeat]=${value}`;

    axios.get(deleteURL).then((response) => {
      props.isVisible(false);
      props.setConfirm({ isShow: false, eventCount: null });
    });
  };

  const dateTime = (dataTime) => {
    const date = new Date(dataTime * 1000);
    return date.toDateString();
  };

  const closePopup = () => {
    props.isVisible(false);
    props.setConfirm({ isShow: false, eventCount: null });
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      // color: theme.palette.tooltip.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.tooltip.backgroundColor,
      color: theme.palette.tooltip.main,
      border: "1px solid white",
    },
  }));
  //In case of edit I need to send repeatID as well
  return (
    <>
      {!props.confirm.isShow ? (
        <Box className="" onClick={(e) => e.stopPropagation()}>
          {calendarEvent.event ? (
            <>
              <DialogTitle
                className="title"
                sx={{
                  backgroundColor: "popup.titleHeaderColor",
                  color: "popup.popupheadertextcolor",
                }}
              >
                <Typography variant="h6">{calendarEvent.event.name}</Typography>
                <BootstrapTooltip title="Close">
                  <Button
                    sx={{
                      backgroundColor: "secondaryColor.main",
                      color: "white",
                    }}
                    className="popupCloseButton"
                    onClick={closePopup}
                    color="popupbutton"
                    variant="contained"
                  >
                    {" "}
                    <CloseIcon />
                  </Button>
                </BootstrapTooltip>
              </DialogTitle>
              <DialogContent
                sx={{
                  backgroundColor: "cardColor.main",
                  color: "cardColor.contrast",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextIcon
                      text={"Date"}
                      icon={<AiOutlineClockCircle size={24} />}
                    />
                    <Box className="modalContent">
                      {isEditing ? (
                        <p>{dateTime(calendarEvent.event.timesort)}</p>
                      ) : (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            value={editedData.editEventDate}
                            onChange={handleDateChange}
                            placeholder={dateTime(calendarEvent.event.timesort)}
                            ampm={false}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                            disablePast
                          />
                        </LocalizationProvider>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <TextIcon
                      text={`Event ${curLanguage.name}`}
                      icon={<MdOutlineCalendarMonth size={24} />}
                    />
                    <Box className="modalContent">
                      {isEditing ? (
                        <p>{calendarEvent.event.name}</p>
                      ) : (
                        <TextField
                          id="outlined"
                          placeholder={calendarEvent.event.name}
                          name="editEventName"
                          value={editedData.editEventName}
                          onChange={handleContentChange}
                          type="text"
                          fullWidth
                        />
                      )}
                    </Box>
                  </Grid>
                  {userinfo[0]?.department.toLowerCase() === "student" && (
                    <Grid item md={6}>
                      <TextIcon text={`Subject`} icon={<BsBook size={24} />} />
                      <Box className="modalContent">
                        <p>{calendarEvent.event.course?.fullname}</p>
                      </Box>
                    </Grid>
                  )}

                  <Grid item md={6}>
                    <TextIcon
                      text={`Created By`}
                      icon={<AiOutlineUser size={24} />}
                    />
                    <Box className="modalContent">
                      <p>{calendarEvent.event.userid}</p>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <TextIcon
                      text={curLanguage.description}
                      icon={<IoDocumentTextOutline size={24} />}
                    />
                    <Box className="modalContent">
                      {isEditing ? (
                        <p>{parseHTML(calendarEvent.event.description)}</p>
                      ) : (
                        <TextField
                          id="outlined"
                          placeholder={calendarEvent.event.description}
                          name="editDescription"
                          value={editedData.editDescription}
                          onChange={handleContentChange}
                          type="text"
                          multiline
                          fullWidth
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              {calendarEvent.event.candelete ? (
                <DialogActions
                  sx={{
                    borderColor: "secondaryColorOutline.border",
                    backgroundColor: "cardColor.main",
                  }}
                >
                  <PrimaryOutlineBtn onClick={handleDelete}>
                    {curLanguage.delete}
                  </PrimaryOutlineBtn>
                  {isEditing && (
                    <PrimaryBgBtn onClick={handleSaveBtn}>
                      {curLanguage.edit}
                    </PrimaryBgBtn>
                  )}

                  {saveBtn && (
                    <PrimaryBgBtn
                      onClick={() =>
                        handleSaveEditData(
                          calendarEvent.event.id,
                          calendarEvent.event.userid,
                          calendarEvent.event.repeatid,
                          calendarEvent.event.timestart
                        )
                      }
                    >
                      {curLanguage.save}
                    </PrimaryBgBtn>
                  )}

                  {moduleNames.indexOf(calendarEvent.event.modulename) > -1 ? (
                    <Button
                      className="deleteEvent"
                      color="popupbutton"
                      variant="contained"
                      onClick={handleSlideshow}
                    >
                      START
                    </Button>
                  ) : (
                    ""
                  )}
                </DialogActions>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}

      {props.confirm.isShow ? (
        <Box className="eventWrapper" onClick={(e) => e.stopPropagation()}>
          {calendarEvent.event ? (
            <>
              <DialogTitle
                className="title"
                sx={{
                  backgroundColor: "popup.titleHeaderColor",
                  color: "popup.popupheadertextcolor",
                }}
              >
                {calendarEvent.event.name}
                <Button
                  className="popupCloseButton"
                  onClick={closePopup}
                  variant="contained"
                  sx={{ minWidth: 10 }}
                >
                  <CloseIcon />{" "}
                </Button>
              </DialogTitle>
              <DialogContent>
                <Box className="confirm">{curLanguage.areYouSure}?</Box>
              </DialogContent>

              <DialogActions
                sx={{
                  borderColor: "secondaryColorOutline.border",
                  backgroundColor: "cardColor.main",
                }}
              >
                {props.confirm.eventCount > 0 ? (
                  <>
                    <PrimaryBgBtn id="0" onClick={multipleDelete}>
                      {curLanguage.delete}
                    </PrimaryBgBtn>

                    <PrimaryOutlineBtn id="1" onClick={multipleDelete}>
                      {curLanguage.deleteAllEvent}
                    </PrimaryOutlineBtn>
                  </>
                ) : (
                  <PrimaryBgBtn id="0" onClick={multipleDelete}>
                    {curLanguage.delete}
                  </PrimaryBgBtn>
                )}
              </DialogActions>
            </>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
export default GetCalendarEvent;
